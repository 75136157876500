import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, RouterProvider} from "react-router-dom"
import {Provider} from "react-redux"
import store from './Redux/Store';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <BrowserRouter> 
     <Provider store = {store}>
        < App />
        </Provider>
       
    </BrowserRouter>
  </React.StrictMode>
);


