import React from 'react'

const CardRapport = ({el}) => {
  return (
    <div >
    <p>Localisation: {el.localisation}</p>
    <p>Date Collect: {el.DateCollect}</p>
    <p>Amount: {el.Amount}</p>
    <p>Feedback: {el.feedback}</p>
  </div>
  )
}

export default CardRapport
