import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './Rapports.css'; // Feuille CSS pour styles
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addRapport } from '../Redux/Actions/RapportActions';



const Rapports = () => {
  const [emplacement, setEmplacement] = useState('');
  const [nbrChaise, setNbrChaise] = useState(0);
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [amount, setAmount] = useState(0);
  const [expense, setExpense] = useState(0);
  const [explanation, setExplanation] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRapport = (e) => {
    e.preventDefault();

    // Validation des champs
    if (!emplacement || nbrChaise <= 0 || !date || amount <= 0 || expense < 0 || !explanation) {
      alert("Veuillez remplir tous les champs correctement.");
      return;
    }
//     const currentDate = new Date();

// const day = currentDate.getDate(); // Day of the month (1-31)
// const month = currentDate.getMonth() + 1; // Month (0-11, so we add 1)
// const year = currentDate.getFullYear(); // Year (e.g., 2024)
// `${year}-${month}-${day}`

    // Données à envoyer
    const rapportData = {
      DateCollect : date.slice(0,10),
      localisation : emplacement,
      ChairNumber: parseInt(nbrChaise),
      Amount : parseFloat(amount),
      MonthExpenses: parseFloat(expense),
      feedback :explanation,
    };

    dispatch(addRapport(rapportData,navigate))

     };

  return (
    <div className="list-rapports">   
      <h3>Rapport Wellness</h3>

    <Form className="rapport-form" >
      
            <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridState" className="rapport-form-group">
          <Form.Label>Localisation</Form.Label>
          <Form.Select 
            onChange={(e) => setEmplacement(e.target.value)} 
            className="rapport-select" 
            defaultValue="Choisissez un emplacement...">
            <option disabled>Choisissez un emplacement...</option>
            <option>Champion Lafayette</option>
            <option>Central Park</option>
            <option>Palmarium</option>
            <option>Zaphir</option>
            <option>Tunis City</option>
            <option>Azur City</option>
            <option>Claridge</option>
            <option>TP-Manar City</option>
            <option>TP-Tunisia Mall</option>
            <option>TP-Jardins D'el Menzah</option>
            <option>Manar City</option>
            <option>Tunisia Mall</option>
            <option>Shell-Grombalia</option>
            <option>Shell-Sidi Khlifa</option>
            <option>Agil-Sidi Khlifa</option>
            <option>Agil-Bourjin</option>
            <option>Agil-Jem</option>
            <option>Sfax Palace</option>
            <option>Djerba Mall</option>
            <option>Bourgo Mall</option>
            <option>TP-Bourgo Mall</option>
            <option>mickey Hammamet</option>
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridChairs" className="rapport-form-group">
          <Form.Label>Nombre de Chaise</Form.Label>
          <Form.Control 
            onChange={(e) => setNbrChaise(e.target.value)} 
            type="number" 
            className="rapport-input" 
            min="0"
          />
        </Form.Group>
      </Row>

      <Row>
      <Form.Group as={Col} controlId="formGridDate" className="rapport-form-group">
      <Form.Label>Date du jour</Form.Label>
      <Form.Control 
        type="date" 
        className="rapport-input" 
        value={date} 
        readOnly // Rend le champ non modifiable
      />
    </Form.Group>

        <Form.Group as={Col} controlId="formGridAmount" className="rapport-form-group">
          <Form.Label>Montant</Form.Label>
          <Form.Control 
            onChange={(e) => setAmount(e.target.value)} 
            type="number" 
            className="rapport-input" 
            placeholder="Entrer le total Collect" 
            min="0"
          />
        </Form.Group>
      </Row>

      <Form.Group controlId="formGridExpenses" className="rapport-form-group">
        <Form.Label>Dépenses effectuées</Form.Label>
        <Form.Control 
          onChange={(e) => setExpense(e.target.value)} 
          type="number" 
          className="rapport-input" 
          min="0"
        />
      </Form.Group>

      <Form.Group controlId="explanationTextarea" className="rapport-form-group">
        <Form.Label>Explication des dépenses</Form.Label>
        <Form.Control 
          onChange={(e) => setExplanation(e.target.value)} 
          as="textarea" 
          rows={3} 
          className="rapport-textarea" 
        />
      </Form.Group>

      <Form.Group controlId="formGridCheckbox" className="mb-3">
        <Form.Check 
          type="checkbox" 
          label="Confirmer vos données" 
          required
        />
      </Form.Group>

      <Button onClick={(e) => handleRapport(e)} variant="primary" type="submit" className="rapport-button" >
        Soumettre
      </Button>
    </Form>
    </div>
  );
};

export default Rapports;
