import React, { useState, useEffect } from "react"; 
import { useDispatch, useSelector } from "react-redux";
import { getrapportwell } from "../Redux/Actions/RapportActions";
import CardRapport from "./CardRapport";
import './ListRapports.css'; // Assurez-vous d'importer le fichier CSS
import { Table, Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

function ListRapports() {
  const dispatch = useDispatch();
  
  // Fetch reports from Redux store when the component loads
  useEffect(() => {
    dispatch(getrapportwell());
  }, [dispatch]);

  const rapports = useSelector((state) => state.RapportReducer.rapports);

  const calcul=(t)=>{
    var totalAmount = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.Amount,
      0
    )

    var totalMonthExpenses = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.MonthExpenses,
      0
    )

    var totalChairNumber = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.ChairNumber,
      0
    )

    var resultatnet = totalAmount - totalMonthExpenses

    var averegeChair = totalAmount/totalChairNumber

    return {totalAmount,totalMonthExpenses,resultatnet,averegeChair}
  }

  const [somme,setSomme] = useState(0)
  // States for filters
  const [localisation, setLocalisation] = useState("All");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState(rapports);
  const [recherche,setRecherche] = useState(false)


  // Function to apply the filter
    const filterData = () => {
    setRecherche(true)
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filtered = rapports.filter((entry) => {
      const entryDate = new Date(entry.DateCollect);

      // Check if location is "All" or matches the entry location
      const isLocationMatch = localisation === "All" || entry.localisation === localisation;

      // Check if the date falls within the specified range
      const isDateInRange = (!start || entryDate >= start) && (!end || entryDate <= end);

      return isLocationMatch && isDateInRange;
    });

    setFilteredData(filtered);
    setSomme(calcul(filtered))
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Rapport Résultats", 10, 10);
    doc.autoTable({
      startY: 20,
      head: [['#', 'Localisation', 'Amount', 'Expenses', 'Net']],
      body: filteredData.map((el, i) => [i + 1, el.localisation, el.Amount, el.MonthExpenses, el.Amount - el.MonthExpenses])
    });
    doc.save('rapport_resultats.pdf');
  };

  // Fonction pour télécharger en Excel
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredData.map((el, i) => ({
        "#": i + 1,
        "Localisation": el.localisation,
        "Amount": el.Amount,
        "Expenses": el.MonthExpenses,
        "Net": el.Amount - el.MonthExpenses,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Rapports");
    XLSX.writeFile(workbook, 'rapport_resultats.xlsx');
  };



  return (
    <div className="list-rapports"> {/* Classe ajoutée pour le CSS */}
      <h3>Recherche de Rapport</h3>

      {/* Filter Form */}
      <div className="filter-form">
        <label>
          Localisation:
          <select value={localisation} onChange={(e) => setLocalisation(e.target.value)}>
            <option value="All">All</option>
            <option>Champion Lafayette</option>
            <option>Central Park</option>
            <option>Palmarium</option>
            <option>Zaphir</option>
            <option>Tunis City</option>
            <option>Azur City</option>
            <option>Claridge</option>
            <option>TP-Manar City</option>
            <option>TP-Tunisia Mall</option>
            <option>TP-Jardins D'el Menzah</option>
            <option>Manar City</option>
            <option>Tunisia Mall</option>
            <option>Shell-Grombalia</option>
            <option>Shell-Sidi Khlifa</option>
            <option>Agil-Sidi Khlifa</option>
            <option>Agil-Bourjin</option>
            <option>Agil-Jem</option>
            <option>Sfax Palace</option>
            <option>Djerba Mall</option>
            <option>Bourgo Mall</option>
            <option>TP-Bourgo Mall</option>
          </select>
        </label>

        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>

        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>

        {/* Confirm button to apply filter */}
        <button onClick={filterData}>Confirm</button>
      </div>

      {/* Display Filtered Data */}
      {
      recherche &&
      <div className="filtered-data">
        <h3>Résultats trouvés</h3>
        <div className="results-summary">
          <h2>Amount : {somme.totalAmount}</h2> 
          <h2>Expenses : {somme.totalMonthExpenses}</h2> 
          <h2>Net : {somme.resultatnet}</h2> 
          <h2>Chair : {Number(somme.averegeChair).toFixed(2)}</h2> 
        </div>
        <Button variant="primary" onClick={downloadPDF} style={{ marginRight: "10px" }}>
          Télécharger en PDF
        </Button>
        <Button variant="success" onClick={downloadExcel}>
          Télécharger en Excel
        </Button>

        {filteredData.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Rapport</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((el, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>
                  <CardRapport el={el} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>Aucun résultat trouvé</p>
        )}
      </div>
    }   
    </div>
  );
}

export default ListRapports;
