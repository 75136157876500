import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { updateSheet } from '../Redux/Actions/SheetActions';
import { useDispatch } from 'react-redux';

function ValidEntree({userId,sheetId,getNow}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch()
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Start
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Start</Modal.Title>
        </Modal.Header>
        <Modal.Body>Validate ? </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button onClick={()=> {dispatch(updateSheet(userId,sheetId,{start : true, entree : getNow().formattedTime}));handleClose()}}>Validate</Button>
        
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ValidEntree;