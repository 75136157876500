import React from 'react';
import Button from 'react-bootstrap/Button';
import './CardRequests.css';

const CardRequests = ({ el }) => {
  const { service, owner, destinataire, validation } = el;

  const isValid = validation.reduce((sum, item) => sum + (item.status === "Confirmed" ? 1 : 0), 0) === validation.length;
  const validationRequest = (el, id) => el.validation.find((item) => item.user === id);

  return (
    <div className="card-requests-container">
      <table className="card-requests-table">
        <tbody>
          <tr>
            <td className="cell">
              <Button type="button" aria-label={`Demande: ${service}`}>
                Demande : {service}
              </Button>
            </td>
            <td className="cell"><strong>Réponse du responsable :</strong> {owner.name}</td>
            <td className="cell"><strong>Demande de :</strong> {owner.name}</td>
            <td className="cell">
              <ul>
                {destinataire.map((elo) => (
                  <li key={elo._id}>
                    {elo.name} {validationRequest(el, elo._id).status} {validationRequest(el, elo._id).explanation}
                  </li>
                ))}
              </ul>
            </td>
            <td>
              <strong>{el.createdAt.slice(0, 10)} {el.createdAt.slice(11, 16)}</strong>
            </td>
            <td className="cell">
              <button className={`status-button ${isValid ? 'valid' : 'not-valid'}`}>
                {isValid ? "Statuts : Valid" : "Statuts : Not Valid"}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CardRequests;
