import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOvertimeRequests } from '../Redux/Actions/OvertimeActions';
import { Container, Table, Spinner } from 'react-bootstrap';
import './ReponseHS.css';

function ReponseAllHS() {
  const dispatch = useDispatch();

  // Sélectionner les données dans le state Redux
  const { overtimeRequests = [], loading } = useSelector((state) => state.OvertimeRequestReducer);
  const Users = useSelector((state) => state.AuthReducer.Users || []);
  const user = useSelector((state) => state.AuthReducer.user || {});
  const salaireMensuel = user?.salaire || 0;

  // Charger les demandes d'heures supplémentaires au montage du composant
  useEffect(() => {
    dispatch(getAllOvertimeRequests());
  }, [dispatch]);

  // Filtrer les demandes pour ne conserver que celles du mois courant
  const filterRequestsByCurrentMonth = () => {
    const currentMonth = new Date().getMonth(); // Mois courant (0 pour janvier, 11 pour décembre)
    const currentYear = new Date().getFullYear(); // Année courante

    return overtimeRequests.filter((request) => {
      const requestDate = new Date(request.date); // Crée un objet Date à partir de la date de la demande
      return requestDate.getMonth() === currentMonth && requestDate.getFullYear() === currentYear;
    });
  };

  // Fonction pour trouver le nom de l'employé en fonction de son ID
  const getEmployeeName = (employeId) => {
    const employee = Users.find((user) => user._id === employeId);
    return employee ? employee.name : 'Non spécifié';
  };

  // Fonction pour calculer les heures supplémentaires approuvées et leur montant
  const calculateOvertimeAmount = (startHour, endHour) => {
    const start = new Date(`2023-01-01T${startHour}:00`);
    const end = new Date(`2023-01-01T${endHour}:00`);
    const diff = (end - start) / 1000 / 60 / 60; // Différence en heures
    const hoursPerMonth = 208; // Nombre moyen d'heures par mois
    const hourlyWage = salaireMensuel / hoursPerMonth; // Salaire horaire

    const overtime = diff > 0 ? diff : 0; // Heures supplémentaires positives
    const overtimeAmount = overtime * hourlyWage * 1.75; // Calcul du montant avec le coefficient 1.75
    return { overtime, overtimeAmount };
  };

  // Appliquer le filtre pour obtenir uniquement les demandes du mois courant
  const currentMonthRequests = filterRequestsByCurrentMonth();

  return (
    <Container className="container mt-4">
      <div className="reponsehs-container">
        <h3 className="reponsehs-title">
          Historique des Demandes d'Heures Supplémentaires -{' '}
          {new Date().toLocaleString('fr-FR', { month: 'long', year: 'numeric' })}
        </h3>

        {/* Affichage de l'état de chargement */}
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Chargement...</span>
            </Spinner>
          </div>
        ) : currentMonthRequests.length > 0 ? (
          <Table striped bordered hover className="reponsehs-table">
            <thead>
              <tr>
                <th>Employé</th>
                <th>Date</th>
                <th>Heure Début</th>
                <th>Heure Fin</th>
                <th>Statut</th>
                <th>Montant Heures Supplémentaires</th>
              </tr>
            </thead>
            <tbody>
              {currentMonthRequests.map((request) => {
                const { overtime, overtimeAmount } = calculateOvertimeAmount(
                  request.startHour,
                  request.endHour
                );

                return (
                  <tr key={request._id}>
                    <td>{getEmployeeName(request.employeId)}</td>
                    <td>{request.date}</td>
                    <td>{request.startHour}</td>
                    <td>{request.endHour}</td>
                    <td>{request.status === 'approved' ? 'Approuvé' : 'Rejeté'}</td>
                    <td>
                      {request.status === 'approved'
                        ? `${overtimeAmount.toFixed(2)} DT (${overtime.toFixed(2)} h)`
                        : '0 DT'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p className="reponsehs-empty">
            Aucune demande d'heures supplémentaires approuvée ou rejetée ce mois-ci.
          </p>
        )}
      </div>
    </Container>
  );
}

export default ReponseAllHS;
