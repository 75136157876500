import{GETTWINRAPPORT} from "../ActionTypes/RapportTwin"
import { ADD_RAPPORT_TWIN_SUCCESS, ADD_RAPPORT_TWIN_FAILURE } from "../ActionTypes/RapportTwin"

import axios from "axios"



 export const getrapporttwin= ()=>async(dispatch)=>{
    try {
       const res = await axios.get('/api/RapportsTwin/GetRapportsTwin')
    
       dispatch(
    {
        type :GETTWINRAPPORT,
        payload : res.data.RapportsTwin
    }
    
       )
    
    } catch (error) {
        console.log(error)
    }
    
        }

        export const AddRapportsTwin=(newRapporttwin,navigate)=>async(dispatch)=>{
    
         try {
            
             await axios.post('/api/RapportsTwin/AddRapportsTwin',newRapporttwin,{
              headers : {
                  Authorised : localStorage.getItem('token')
              }
          })
      
             dispatch(getrapporttwin())
      
              navigate('/Accueil')
           
         } catch (error) {
             console.log(error)
         }
      }

      export const AddCollecteTwin=(newCollectetwin,navigate)=>async(dispatch)=>{
    
        try {
           
            await axios.post('/api/RapportsTwin/AddRapportsTwin',newCollectetwin,{
             headers : {
                 Authorised : localStorage.getItem('token')
             }
         })
     
            dispatch(getrapporttwin())
     
             navigate('/ListeCollecteTwin')
          
        } catch (error) {
            console.log(error)
        }
     }