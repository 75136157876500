import './App.css';
import NavAuth from './Components/NavAuth';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Register from './Components/Register';
import Login from './Components/Login';
import Profil from './Components/Profil';
import PrivateRoute from './Components/PrivateRoute';
import Errors from './Components/Errors';
import ListUser from './Components/ListUser';
import UpdateUser from './Components/UpdateUser';
import UpdateProfil from './Components/UpdateProfil';
import Requests from './Components/Requests';
import AddRequests from './Components/AddRequest';
import NProfil from './Components/NProfil';
import Rapports from './Components/Rapports';
import RapportsTwin from './Components/RapportsTwin';
import HumanResources from './Components/HumanResources';
import ListRapports from './Components/ListRapports';
import ListeRapportsTwin from './Components/ListeRapportsTwin';
import CheckListMC from './Components/CheckListMC';
import CheckListTM from './Components/CheckListTM';
import MyQuizs from './Components//MyQuizs';
import CollecteTwin from './Components/CollecteTwin';
import ApplicationFakhry from './Components/ApplicationFakhry';
import HumanRessourcesList from './Components/HumanRessourcesList';
import GestionHeuresSupp from './Components/GestionHeuresSupp';
import GestionHR from './Components/GestionHR';
import ListCollectTwin from './Components/ListCollectTwin';
import MyEmploi from './Components/MyEmploi';
import HomeGraph from './Components/HomeGraph';
import AddQuiz from './Components/AddQuiz';
import QuizDescription from './Components/QuizDescription';
import ReponseQuiz from './Components/ReponseQuiz';
import QuizGroupedResponse from './Components/QuizGroupedResponse';
import Welcome from './Components/Welcome';
import ReponseAllHS from './Components/RsponseAllHS';
import FichePaie from './Components/FichePaie';
import RapportsSpintoss from './Components/RapportsSpintoss';
import ListRapportsSpintoss from './Components/ListRapportsSpintoss';
import Accueil from './Components/Accueil';




function App() {
  return (
    <div>
      
      <NavAuth/>
      <Errors/>
      <Routes>
        
            <Route path='/' element={<Home/>}/>
            <Route path='/Accueil' element={<Accueil/>}/>
            <Route path='/Welcome' element={<Welcome/>}/>
            <Route path='/Register' element={<Register/>}/>
            <Route path='/Login' element={<Login/>}/>
            <Route path='/Profil' element={<PrivateRoute><NProfil/></PrivateRoute>}/>
            <Route path='/AllUser' element ={<ListUser/>}/>
            <Route path='/UpdateUser/:id' element ={<UpdateUser/>}/>
            <Route path='/UpdateProfil' element ={<UpdateProfil/>}/>
            <Route path='/Requests' element ={<Requests/>}/>
            <Route path='/AddRequests' element ={<AddRequests/>}/>
            <Route path='/AddRapports' element = {<Rapports/>}/>
            <Route path='/AddRapportsTwin' element = {<RapportsTwin/>}/>
            <Route path='/HumanResources' element = {<HumanResources/>}/>
            <Route path='/ListRapport' element={<ListRapports/>}/>
            <Route path='/ListeRapportTwin' element={<ListeRapportsTwin/>}/>
            <Route path='/CLManarCity' element={<CheckListMC/>}/>
            <Route path='/AddQuiz' element={<AddQuiz/>}/>
            <Route path='//MyQuizs' element={<MyQuizs/>}/>
            <Route path='/CollecteTwin' element={<CollecteTwin/>}/>
            <Route path='/ListeCollecteTwin' element={<ListCollectTwin/>}/>
            <Route path='/ApplicationFakhry' element={<ApplicationFakhry/>}/>
            <Route path='/HumanRessourcesList' element={<HumanRessourcesList/>}/>
            <Route path='/GestionHeuresSupp' element={<GestionHeuresSupp/>}/>
            <Route path='/GestionHR' element={<GestionHR/>}/>
            <Route path='/MyEmploi' element={<MyEmploi/>}/>
            <Route path='/HomeGraph' element={<HomeGraph/>}/>
            <Route path='/QuizDescription/:id' element={<QuizDescription/>}/>
            <Route path='/ReponseQuiz/:id' element={<ReponseQuiz/>}/>
            <Route path='/QuizGroupedResponse/:id' element={<QuizGroupedResponse/>}/>
            <Route path='/QuizGroupedResponse/:id' element={<QuizGroupedResponse/>}/>
            <Route path='/GestionAllHR' element={<ReponseAllHS/>}/>
            <Route path='/FichePaie' element={<FichePaie/>}/>
            <Route path='/AddRapportsSpintoss' element = {<RapportsSpintoss/>}/>
            <Route path='/ListRapportsSpintoss' element={<ListRapportsSpintoss/>}/>
      </Routes>
    </div>
  );
}

export default App;
