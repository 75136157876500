const initialState = {
    unreadCount: 0, // Assurez-vous que cette valeur existe
  };
  
  const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_NOTIFICATION_COUNT':
        return {
          ...state,
          unreadCount: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default notificationsReducer;
  