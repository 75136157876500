import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyRequests, getRecivedRequests } from "../Redux/Actions/RequestActions";
import { Table, Container } from "react-bootstrap";
import "./Accueil.css"; // Feuille CSS pour les styles
import Login from "./Login";

function Accueil() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      dispatch(getMyRequests());
      dispatch(getRecivedRequests());
    }
  }, [dispatch, token]);

  const myRequests = useSelector((state) => state.ReducersRequest.myRequests);
  const myRecivedRequest = useSelector((state) => state.ReducersRequest.myRecivedRequest);

  const categories = ["All", "For You Today", "Repetitive Tasks", "New Project"];

  const countUnconfirmedRequests = (requests, category) => {
    if (category === "All") {
      const allCategories = ["For You Today", "Repetitive Tasks", "New Project"];
      let count = 0;
      allCategories.forEach((cat) => {
        count += requests.filter(
          (request) =>
            request.selectedCategory?.trim().toLowerCase() === cat.toLowerCase() &&
            request.destinataire.some(dest => dest.status !== "Confirmed")
        ).length;
      });
      return count;
    }
    return requests.filter(
      (request) =>
        request.selectedCategory?.trim().toLowerCase() === category.toLowerCase() &&
        request.destinataire.some(dest => dest.status !== "Confirmed")
    ).length;
  };

  const [todoItems, setTodoItems] = useState([]);
  const [newTask, setNewTask] = useState("");

  const addTodoItem = () => {
    if (newTask.trim() === "") return;
    const newItem = { id: Date.now(), text: newTask, completed: false };
    setTodoItems([...todoItems, newItem]);
    setNewTask("");
  };

  const toggleCompletion = (id) => {
    setTodoItems(todoItems.map((item) => (item.id === id ? { ...item, completed: !item.completed } : item)));
  };

  const removeTodoItem = (id) => {
    setTodoItems(todoItems.filter((item) => item.id !== id));
  };

  return (
    <div className="app-container">
      {token ? (
        <Container>
          <div className="details-section">
            <h3 className="section-title">Détails des demandes</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  
                  <th>Demandes Non Confirmées</th>
                  <th>Demandes envoyées</th>
                  <th>Demandes Reçues</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category) => (
                  <tr key={category}>
                    <td>{category}</td>
                    <td>{countUnconfirmedRequests(myRequests, category)}</td>
                    <td>{countUnconfirmedRequests(myRecivedRequest, category)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Section Todo-List */}
          <div className="todo-list-section">
            <h3 className="todo-title">To Do List</h3>

            {/* Champ de saisie et bouton Ajouter */}
            <div className="todo-input-container">
              <input
                type="text"
                value={newTask}
                onChange={(e) => setNewTask(e.target.value)}
                placeholder="Ajouter une tâche"
                className="todo-input"
              />
              <button onClick={addTodoItem} className="todo-button">Ajouter</button>
            </div>

            {/* Liste des tâches */}
            <div className="todo-list">
              {todoItems.map((item) => (
                <div key={item.id} className="todo-item">
                  <input
                    type="checkbox"
                    checked={item.completed}
                    onChange={() => toggleCompletion(item.id)}
                    className="checkbox"
                  />
                  <span className={item.completed ? "todo-text completed" : "todo-text"}>{item.text}</span>
                  <button onClick={() => removeTodoItem(item.id)} className="remove-button">✖</button>
                </div>
              ))}
            </div>
          </div>
        </Container>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default Accueil;
