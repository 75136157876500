import React, { useEffect } from 'react';
import { getAllSheets } from '../Redux/Actions/SheetActions';
import { useDispatch, useSelector } from 'react-redux';

const GestionPaie = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSheets());
  }, [dispatch]);

  const allSheets = useSelector(state => state.SheetReducer.allSheets);

  // Filtrer les feuilles de paie où end === true (feuilles terminées)
  const filteredSheets = allSheets.filter(sheet => sheet.end === true);

  // Grouper les feuilles de paie par owner._id pour calculer les totaux
  const groupedSheets = filteredSheets.reduce((acc, sheet) => {
    const ownerId = sheet.owner?._id;
    if (!acc[ownerId]) {
      acc[ownerId] = {
        owner: sheet.owner,
        totalHeures: 0,
        totalRetard: 0,
        totalPenalite: 0,
        totalSalaire: 0,
      };
    }
    // Accumuler les données pour chaque propriétaire
    acc[ownerId].totalHeures += sheet.nbrHeuresJournalier / 60; // Convertir les minutes en heures
    acc[ownerId].totalRetard += (sheet.penaliteEntree + sheet.penaliteSortie) / 60; // Convertir la pénalité en heures
    acc[ownerId].totalPenalite += sheet.penaliteTotale; // Pénalité en €
    acc[ownerId].totalSalaire += sheet.salaireNet; // Salaire net en €
    return acc;
  }, {});

  // Créer une liste des totaux par propriétaire
  const ownerTotals = Object.keys(groupedSheets).map(ownerId => {
    const ownerData = groupedSheets[ownerId];

    return {
      owner: ownerData.owner,
      totalHeures: ownerData.totalHeures.toFixed(2),
      totalRetard: ownerData.totalRetard.toFixed(2),
      totalPenalite: ownerData.totalPenalite.toFixed(2),
      totalSalaire: ownerData.totalSalaire.toFixed(2),
    };
  });

  return (
    <div>
      <h3>Suivi par employé</h3>
      <table>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Heures travaillées</th>
            <th>Heures de Retard (h)</th>
            <th>Pénalités (DT)</th>
            <th>Total Salaire (DT)</th>
          </tr>
        </thead>
        <tbody>
          {ownerTotals.map((data) => (
            <tr key={data.owner?._id}>
              <td>{data.owner?.name}</td>
              <td>{data.totalHeures} h</td>
              <td>{data.totalRetard} h</td>
              <td>{data.totalPenalite} </td>
              <td>{data.totalSalaire} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GestionPaie;
