import {ADD_OVERTIME_REQUEST, GET_OVERTIME_REQUESTS, APPROVE_OVERTIME_REQUEST, REJECT_OVERTIME_REQUEST } from "../ActionTypes/OvertimeActionTypes"


import axios from 'axios'



export const getAllOvertimeRequests = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/Overtime/get')
    dispatch({
      type: GET_OVERTIME_REQUESTS,
      payload: res.data.requests,
    });
  } catch (error) {
    console.error('Erreur lors de la récupération des demandes :', error);
  }
};


// Ajouter une demande

export const addOvertimeRequest = (newRequest) => async (dispatch) => {
  try {
    await axios.post('/api/overtime/add', newRequest)

    dispatch(getAllOvertimeRequests())
      
   
  } catch (error) {
    console.error('Erreur lors de l’ajout de la demande :', error);
  }
};




// Approuver une demande
export const approveOvertimeRequest = (requestId) => async (dispatch) => {
  try {
    const res = await axios.patch(`/api/overtime/${requestId}/approve`); // PATCH pour mise à jour
    dispatch({
      type: APPROVE_OVERTIME_REQUEST,
      payload: res.data.requestId, // Retourne l'ID de la demande approuvée
    });
  } catch (error) {
    console.error('Erreur lors de l’approbation de la demande :', error);
  }
};

// Rejeter une demande
export const rejectOvertimeRequest = (requestId) => async (dispatch) => {
  try {
    const res = await axios.patch(`/api/overtime/${requestId}/reject`); // PATCH pour mise à jour
    dispatch({
      type: REJECT_OVERTIME_REQUEST,
      payload: res.data.requestId, // Retourne l'ID de la demande rejetée
    });
  } catch (error) {
    console.error('Erreur lors du rejet de la demande :', error);
  }
};
export const getMyOvertimeRequests = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/overtime/requests/${userId}`); // Assurez-vous que votre API prend en charge cette route
    dispatch({ type: 'GET_MY_OVERTIME_REQUESTS', payload: res.data });
  } catch (error) {
    console.error('Erreur lors de la récupération des demandes d\'heures supplémentaires :', error);
  }
};
export const setCoefficient = (requestId, value) => {
  return {
    type: 'SET_COEFFICIENT',
    payload: { requestId, value },
  };
};

