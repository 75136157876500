import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHR, updateHR, deleteHR } from '../Redux/Actions/HumainRessourcesActions';
import './HumanRessourcesList.css';

const HumanRessourcesList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHR());
  }, [dispatch]);

  const humanRessources = useSelector((state) => state.HumanRessourcesReducer.humanRessources);

  const [editedHR, setEditedHR] = useState(null); // Emploi du temps en cours d'édition
  const [updatedSchedule, setUpdatedSchedule] = useState({}); // Contenu modifié

  const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
  const creneaux = [
    { label: "08h - 10h", heures: 2 },
    { label: "10h - 12h", heures: 2 },
    { label: "12h - 14h", heures: 2 },
    { label: "14h - 16h", heures: 2 },
    { label: "16h - 17h", heures: 1 },
    { label: "17h - 18h", heures: 1 },
    { label: "18h - 19h", heures: 1 },
    { label: "19h - 20h", heures: 1 },
    { label: "20h - 21h", heures: 1 },
    { label: "21h - 22h", heures: 1 },
    { label: "22h - 23h", heures: 1 }
  ];



  const startEditing = (resource) => {
    setEditedHR(resource); // Définir l'employé en cours d'édition
    setUpdatedSchedule({ ...resource.emploisDuTemps }); // Copier son emploi du temps
  };
  

  const saveChanges = () => {
    if (editedHR) {
      // Appeler l'action Redux avec l'ID de l'employé et l'emploi du temps modifié
      dispatch(updateHR(editedHR._id, updatedSchedule));
  
      // Réinitialiser l'état local après l'enregistrement
      setEditedHR(null);
      setUpdatedSchedule({});
    }
  };

  const cancelEditing = () => {
    setEditedHR(null); // Quitter le mode édition
    setUpdatedSchedule({}); // Réinitialiser les modifications locales
  };

  const toggleSchedule = (cellKey) => {
    setUpdatedSchedule((prev) => ({
      ...prev,
      [cellKey]: !prev[cellKey], // Basculer l'état actif/inactif
    }));
  };

  return (
    <div className="human-resources-container">
      <h3>Gestion des Emplois du Temps</h3>
      {humanRessources && humanRessources.map((resource, i) => (
  <div key={i}>
    <h4>Emploi du Temps pour {resource.owner?.name || 'Employé Inconnu'}</h4>
    <table>
      <thead>
        <tr>
          <th>Jour</th>
          {creneaux.map((creneau, index) => (
            <th key={index}>{creneau.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {jours.map((jour, jourIndex) => (
          <tr key={jourIndex}>
            <td>{jour}</td>
            {creneaux.map((_, creneauIndex) => {
              const cellKey = `${resource.owner?._id}-${jourIndex}-${creneauIndex}`;
              const isActive = editedHR
                ? updatedSchedule[cellKey]
                : resource.emploisDuTemps?.[cellKey];

              return (
                <td
                  key={creneauIndex}
                  className={isActive ? 'cellule-active' : ''}
                  style={{
                    backgroundColor: isActive ? "red" : "white",
                    cursor: editedHR ? 'pointer' : 'default',
                  }}
                  onClick={() => {
                    if (editedHR) toggleSchedule(cellKey);
                  }}
                >
                  {isActive ? "✓" : ""}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
    <button onClick={() => startEditing(resource)}>Modifier</button>
    {editedHR && editedHR._id === resource._id && (
      <div className="edit-actions">
        <button onClick={saveChanges}>Enregistrer</button>
        <button onClick={cancelEditing}>Annuler</button>
      </div>
    )}
  </div>
))}
 
    </div>
  );
};

export default HumanRessourcesList;
