
import { GETMYRECIVEDREQUEST, GETMYREQUESTS, GETREQUESTS } from "../ActionTypes/RequestTypes"

const initialState ={

    requests : [],
    myRequests : [],
    myRecivedRequest : []

}

const ReducersRequest=(state=initialState,action)=>{

switch (action.type) {
        case GETREQUESTS : return {...state, requests: action.payload}
        case GETMYREQUESTS : return {...state, myRequests: action.payload}
        case GETMYRECIVEDREQUEST : return {...state, myRecivedRequest: action.payload}
       default: return state
}

}

export default ReducersRequest