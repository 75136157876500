import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateRequest } from '../Redux/Actions/RequestActions';

const ConfButton = ({ el, user, handleUpdate, handleFind }) => {

    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false);
    const [explanation, setExplanation] = useState(""); 




    const handleUpdateR =async (el, status, explanation) => {
         dispatch(updateRequest(el._id,{ validation :el.validation.map((element) =>
        element.user === user._id ? { ...element, status : status, explanation } : element
      )  }))
    }

    const handleAccept = () => {
      handleUpdateR(el, "Confirmed", explanation);
      setShowModal(false);
    };
  
    const handleReject = () => {
      handleUpdate(el, "Rejected", explanation);
      setShowModal(false);
    };



  return (
  
           <>
      {handleFind(el, user._id)?.status === "In progress" && (
        <Button onClick={() => setShowModal(true)}>Confirmation</Button>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation Requise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="explanationTextarea">
            <Form.Label>Explication du responsable</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}       
              onChange={(e) => setExplanation(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleReject}>

            Rejeter
          </Button>
          <Button variant="primary" onClick={()=>{console.log('ACCEPTTT', "confirmed",explanation) ;   handleAccept()}}>
            Accepter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfButton
