import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteUser } from '../Redux/Actions/AuthActions';
import './CardUser.css';
import Register from './Register';

const CardUser = ({ el }) => {
  const dispatch = useDispatch();

  return (
  
    <div className="card-container">
    <Card className='user-card' style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>{el.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{el.email}</Card.Subtitle>
        
        <div className='button-container'>
          
          <Button style={{ height : '40px' }} as={Link} to={`/UpdateUser/${el._id}`} variant='primary'>Update</Button>
          <Button 
            
            variant='danger' 
            onClick={() => dispatch(deleteUser(el._id))}
          >
            Delete
          </Button>
          
        </div>
        
      </Card.Body>
    </Card>
    
           </div>
            
            
        
    
  );
  
};

export default CardUser;
