import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getrapportwell } from "../Redux/Actions/RapportActions";
import { getrapporttwin } from "../Redux/Actions/RapportTwinAction";
import axios from 'axios';
import { Table,Container } from "react-bootstrap";
import "./Home.css"; // Feuille CSS pour styles
import { getMyRequests, getRecivedRequests } from "../Redux/Actions/RequestActions";
import Login from "./Login";
import {PieChart} from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import GestionPaie from "./GestionPaie";
import HomeGraph from './HomeGraph';




function Home() {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token')

  useEffect(() => {
    dispatch(getrapportwell());
    dispatch(getMyRequests());
    dispatch(getRecivedRequests());
  }, [dispatch]);

  const rapports = useSelector((state) => state.RapportReducer.rapports);

  // Filtrer les rapports pour le mois actuel
  const getCurrentMonthRange = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), 1); // 1er jour du mois
    const end = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Dernier jour du mois
    return { start, end };
  };

  const fetchMonthlyReports = () => {
    const { start, end } = getCurrentMonthRange();
    return rapports.filter((entry) => {
      const entryDate = new Date(entry.DateCollect);
      return entryDate >= start && entryDate <= end;
    });
  };

  const filteredData = fetchMonthlyReports();

  // Calcul des totaux
  const calculateTotals = (data) => {
    const totalAmount = data.reduce((acc, curr) => acc + curr.Amount, 0);
    const totalExpenses = data.reduce((acc, curr) => acc + curr.MonthExpenses, 0);
    const netResult = totalAmount - totalExpenses;

    return { totalAmount, totalExpenses, netResult };
  };

  const { totalAmount, totalExpenses, netResult } = calculateTotals(filteredData);

  // Initialisation des états
  const [somme, setSomme] = useState({
    totalAmount: 0,
    totalDailyExpenses: 0,
    resultatnet: 0,
  });

  const RapportsTwin = useSelector((state) => state.RapportTwinReducer.RapportsTwin);

  // Vérification si 'rapports' est un tableau valide
  const validRapports = Array.isArray(RapportsTwin) ? RapportsTwin : [];

  // Dispatch action pour récupérer les rapports au montage du composant
  useEffect(() => {
    dispatch(getrapporttwin());
  }, [dispatch]);

  // Fonction pour calculer les totaux
  const calcul = (data) => {
    const totalAmount = data.reduce((acc, cur) => acc + (cur.TotalAmount || 0), 0);
    const totalDailyExpenses = data.reduce(
      (acc, cur) => acc + (cur.DailyExpenses || 0),
      0
    );
    const resultatnet = totalAmount - totalDailyExpenses;

    return {
      totalAmount,
      totalDailyExpenses,
      resultatnet,
    };
  };

  // Fonction pour exclure les données liées au partenariat
  const filterNonPartnershipData = (data) => {
    return data.filter((cur) => {
      return !(
        cur.MachineBoxe ||
        cur.MachineFoot ||
        cur.MachineBoxeEnfant ||
        cur.BillardAmericain ||
        cur.BallesGift
      );
    });
  };

  // Application du filtre pour exclure les données partenariat
  const validRapportsWithoutPartnership = filterNonPartnershipData(validRapports);

  // Regroupement des rapports par localisation
  const groupByLocalisation = (data) => {
    return data.reduce((acc, el) => {
      if (!acc[el.localisation]) {
        acc[el.localisation] = [];
      }
      acc[el.localisation].push(el);
      return acc;
    }, {});
  };

  const groupedReports = groupByLocalisation(validRapportsWithoutPartnership);

  // Calcul des sommes globales
  useEffect(() => {
    setSomme(calcul(validRapportsWithoutPartnership));
  }, [validRapportsWithoutPartnership]);

  // Filtrage des données de partenariat
  const filterPartnershipData = (data) => {
    return data.filter((cur) => {
      return (
        cur.MachineBoxe ||
        cur.MachineFoot ||
        cur.MachineBoxeEnfant ||
        cur.BillardAmericain ||
        cur.BallesGift
      );
    });
  };

  const validPartnershipReports = filterPartnershipData(validRapports);

  // Regroupement des rapports de partenariat par localisation
  const groupedPartnershipReports = groupByLocalisation(validPartnershipReports);

  // Calcul des totaux pour les données de partenariat
  const calculPartnership = (data) => {
    const totalAmountPartnership = data.reduce(
      (acc, cur) =>
        acc +
        (cur.MachineBoxe || 0) +
        (cur.MachineFoot || 0) +
        (cur.MachineBoxeEnfant || 0) +
        (cur.BillardAmericain || 0) +
        (cur.BallesGift || 0),
      0
    );

    const totalExpensesPartnership = data.reduce(
      (acc, cur) => acc + (cur.DailyExpenses || 0),
      0
    );

    const netResultPartnership = totalAmountPartnership - totalExpensesPartnership;

    return {
      totalAmountPartnership,
      totalExpensesPartnership,
      netResultPartnership,
    };
  };

  const [sommePartnership, setSommePartnership] = useState({});

  useEffect(() => {
    if (validPartnershipReports.length > 0) {
      setSommePartnership(calculPartnership(validPartnershipReports));
    }
  }, [validPartnershipReports]);

  const myRequests = useSelector((state) => state.ReducersRequest.myRequests);
  const myRecivedRequest = useSelector((state) => state.ReducersRequest.myRecivedRequest);

  // Fonction pour calculer le nombre de demandes non confirmées dans une catégorie donnée
  const categories = ["All", "For You Today", "Repetitive Tasks", "New Project"];

// Fonction pour compter les demandes non confirmées
const countUnconfirmedRequests = (requests, category) => {
    // Si la catégorie est "All", on additionne les demandes non confirmées des trois catégories spécifiques
    if (category === "All") {
        const allCategories = ["For You Today", "Repetitive Tasks", "New Project"];
        let count = 0;
        
        allCategories.forEach((cat) => {
            count += requests.filter(
                (request) =>
                    request.selectedCategory?.trim().toLowerCase() === cat.toLowerCase() &&
                    request.destinataire.some(destinataire => destinataire.status !== "Confirmed")
            ).length;
        });
        
        return count;
    }

    // Pour les autres catégories, on filtre normalement
    return requests.filter(
        (request) =>
            request.selectedCategory?.trim().toLowerCase() === category.toLowerCase() &&
            request.destinataire.some(destinataire => destinataire.status !== "Confirmed")
    ).length;
};

const calculateGlobalTotals = () => {
  const globalAmount = totalAmount + somme.totalAmount + (sommePartnership.totalAmountPartnership || 0);
  const globalExpenses = totalExpenses + somme.totalDailyExpenses + (sommePartnership.totalExpensesPartnership || 0);
  const globalNetResult = globalAmount - globalExpenses;

  return { globalAmount, globalExpenses, globalNetResult };
};

const { globalAmount, globalExpenses, globalNetResult } = calculateGlobalTotals();

// const [retards, setRetards] = useState([]);
//     const [dateNow, setDateNow] = useState(new Date().toLocaleDateString());  // Format de la date

//     const myHumanRessources = useSelector((state) => state.HumanRessourcesReducer.myHumanRessources);
//     const user = useSelector((state) => state.UserReducer.user);

//     const resource = myHumanRessources?.find((el) => el.owner?._id === user?._id);

//     useEffect(() => {
//         if (resource) {
//             // Faire l'appel à l'API pour récupérer les retards
//             axios.get('/api/sheets/retards', {
//                 params: {
//                     userId: resource._id,
//                     date: dateNow,  // La date au format souhaité
//                 }
//             })
//             .then(response => {
//                 setRetards(response.data);  // Mettre à jour l'état avec les retards récupérés
//             })
//             .catch(err => {
//                 console.error('Erreur lors de la récupération des retards', err);
//             });
//         }
//     }, [resource, dateNow]);

// TODOLIST

  const [todoItems, setTodoItems] = useState([]);
  const [newItem, setNewItem] = useState("");

  const addTodoItem = (event) => {
    event.preventDefault();

    if (newItem.trim()) {
      setTodoItems([
        ...todoItems,
        { text: newItem, completed: false, id: Date.now() },
      ]);
      setNewItem(""); // Réinitialise le champ d'entrée
    }
  };

  const toggleCompletion = (id) => {
    setTodoItems(
      todoItems.map((item) =>
        item.id === id ? { ...item, completed: !item.completed } : item
      )
    );
  };

  const removeTodoItem = (id) => {
    setTodoItems(todoItems.filter((item) => item.id !== id));
  };

  <HomeGraph
    totalAmount={totalAmount}
    somme={somme}
    sommePartnership={sommePartnership}
    totalExpenses={totalExpenses}
    netResult={netResult}
/>
  return (
   <div>
    
    {
      token ? 

      <div className="home-container">
        <GestionPaie/>
      <div>
      {/* ... autres tableaux */}
      
      {/* Tableau récapitulatif */}
      <h3>Résumé Global</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Libellé</th>
            <th>Recettes Globales</th>
            <th>Dépenses Globales</th>
            <th>Résultat Net Globales</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Global</td>
            <td>{globalAmount}</td>
            <td>{globalExpenses}</td>
            <td>{globalNetResult}</td>
          </tr>
        </tbody>
      </Table>
      </div>

      
      <BarChart
      xAxis={[{ scaleType: 'band', data: ['Wellness', 'Twin Park', '<Partenariat'] }]}
      series={[{ data: [totalAmount, somme.totalAmount, sommePartnership.totalAmountPartnership] }, { data: [totalExpenses, somme.totalDailyExpenses, sommePartnership.totalExpensesPartnership] }, { data: [netResult, somme.resultatnet, sommePartnership.netResultPartnership] }]}
      width={500}
      height={300}
      barLabel="value"
    />




      <PieChart
  series={[
    {
      data: [
        { id: 0, value: globalExpenses, label: "Dépenses Totales" },
        { id: 1, value: globalNetResult, label: "Résultat Net" },
      ],
    },
  ]}
  width={400}
  height={200}
/>
   
      <h3>Rapports Wellness du mois</h3>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Libéllé</th>
            <th>Recettes Totales</th>
            <th>Dépenses Totales</th>
            <th>Résultat Net</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Wellness</td>
            <td>{totalAmount}</td>
            <td>{totalExpenses}</td>
            <td>{netResult}</td>
          </tr>
        </tbody>
      </Table>

      {/* Tableau des rapports regroupés par localisation avec les totaux globaux */}
      <div className="filtered-reports-section">
        <h3>Rapports Twin Park du mois</h3>
        {Object.keys(groupedReports).length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Localisation</th>
                <th>Recettes Totales</th>
                <th>Dépenses Totales</th>
                <th>Résultat Net</th>
              </tr>
            </thead>
            <tbody>
              {/* Lignes pour les localisations */}
              {Object.keys(groupedReports).map((localisation, idx) => {
                const reportsForLocalisation = groupedReports[localisation];
                const totalsForLocalisation = calcul(reportsForLocalisation);
                return (
                  <tr key={idx}>
                    <td>{localisation}</td>
                    <td>{totalsForLocalisation.totalAmount}</td>
                    <td>{totalsForLocalisation.totalDailyExpenses}</td>
                    <td>{totalsForLocalisation.resultatnet}</td>
                  </tr>
                );
              })}


              {/* Ligne pour les totaux globaux */}

              <tr style={{ fontWeight: "bold" }}>
                <td>Global</td>
                <td>{somme.totalAmount}</td>
                <td>{somme.totalDailyExpenses}</td>
                <td>{somme.resultatnet}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <p>Aucun rapport disponible pour ce mois.</p>
        )}
      </div>

      {/* Nouveau tableau uniquement pour le Partenariat avec localisation */}
      <h3>Rapport Partenariat</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Localisation</th>
            <th>Recettes Totales</th>
            <th>Dépenses Totales</th>
            <th>Résultat Net</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedPartnershipReports).length > 0 ? (
            Object.keys(groupedPartnershipReports).map((localisation, idx) => {
              const reportsForLocalisation = groupedPartnershipReports[localisation];
              const totalsForLocalisation = calculPartnership(reportsForLocalisation);
              return (
                <tr key={idx}>
                  <td>{localisation}</td>
                  <td>{totalsForLocalisation.totalAmountPartnership}</td>
                  <td>{totalsForLocalisation.totalExpensesPartnership}</td>
                  <td>{totalsForLocalisation.netResultPartnership}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>Aucun rapport de partenariat trouvé.</td>
            </tr>
          )}
          {/* Ligne des totaux globaux de partenariat */}
          <tr style={{ fontWeight: "bold" }}>
            <td>Total Partenariat</td>
            <td>{sommePartnership.totalAmountPartnership}</td>
            <td>{sommePartnership.totalExpensesPartnership}</td>
            <td>{sommePartnership.netResultPartnership}</td>
          </tr>
        </tbody>
      </Table>
      
      <div className="details-section">
    <h3>Détails des demandes</h3>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Demandes Non Confirmées</th>
          <th>Demandes envoyées</th>
          <th>Demandes Reçues</th>
        </tr>
      </thead>
      <tbody>
        {categories.map((category) => (
          <tr key={category}>
            <td>{category}</td>
            <td>{countUnconfirmedRequests(myRequests, category)}</td>
            <td>{countUnconfirmedRequests(myRecivedRequest, category)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>

  {/* Section Todo-List */}
  <div className="todo-section">
    <form className="todo-form" onSubmit={addTodoItem}>
      <input
        type="text"
        className="todo-list-input"
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
        placeholder="Ajouter une tâche"
      />
      <button type="submit" className="todo-list-add-btn">
        Ajouter
      </button>
    </form>

    <ul className="todo-list">
      {todoItems.map((item) => (
        <li key={item.id} className={item.completed ? "completed" : ""}>
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="checkbox"
                checked={item.completed}
                onChange={() => toggleCompletion(item.id)}
              />
              {item.text}
            </label>
          </div>
          <button className="remove" onClick={() => removeTodoItem(item.id)}>
            ✖
          </button>
        </li>
      ))}
    </ul>
  </div>
</div>

    :

    <Login/>
    }
   </div>
  );
}

export default Home;



// <table>
        //         <thead>
        //             <tr>
        //                 <th>Zone</th>
        //                 <th>Pourcentage Conforme</th>
        //                 <th>Pourcentage Non Conforme</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {Object.keys(zonePercentages).map((zone) => (
        //                 <tr key={zone}>
        //                     <td>{zone}</td>
        //                     <td>{zonePercentages[zone].pourcentageConforme}%</td>
        //                     <td>{zonePercentages[zone].pourcentageNonConforme}%</td>
        //                 </tr>
        //             ))}
        //         </tbody>
        //     </table> *