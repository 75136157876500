import React, { useState } from 'react';
import Home from './Home';
import './CheckListMC.css';

function CheckListMC() {
    const [selectedZone, setSelectedZone] = useState("Playzone");
    const [tasksStatus, setTasksStatus] = useState({
        Playzone: {},
        AccueilCandy: {},
        Toilette: {},
        ZoneA: {},
        ZoneB: {},
    });
    const [completedZones, setCompletedZones] = useState([]);
    const [comment, setComment] = useState({});
    const [redirectToHome, setRedirectToHome] = useState(false);
    const [checkListResults, setCheckListResults] = useState([]);

    
    const zones = {
        Playzone: [
            "VERIFIER L'ETAT DE LA MOQUETTE REZ DE CHAUSSEE ET ETAGE",
            "VERIFIER LES ELEMENTS SUSPENDU REZ DE CHAUSSEE ET ETAGE",
            "VERIFIER LES BALLES DE LA PISCINES ET LES RAMASSER",
            "VERIFIER LA TOILE REZ DE CHAUSSEE ET ETAGE",
            "VERIFIER LES BARRIERES",
            "VERIFIER LES SERRES CABLES",
            "Nettoyer La poussière a l'aide d'un produit d'entretien neutre",
            "Verifier la playzone en presence de chef d'equipe"
        ],
        AccueilCandy: [
            "Nettoyer La poussière a l'aide d'un produit d'entretien neutre : boutique + comptoir",
            "Nettoyer La poussière a l'aide d'un produit d'entretien neutre : jouets dans la boutique",
            "ORGANISATION DES JOUETS DANS LA BOUTIQUE ET COMPTOIR",
            "ORGANSATION DE L’ACCEUIL",
            "RANGER LE STOCK DES JOUETS A L'INTERIEUR DE LA BOUTIQUES",
            "ORGANISER LES ELEMENTS DU CANDY BAR",
            "NETTOYER LE SOL A L'AIDE D'UN PRODUIT NEUTRE",
            "VERIFICATION AVEC LE CHEF D'EQUIPE"
        ],
        Toilette: [
            "NETTOYER LA MIROIR AVEC UN PRODUIT NEUTRE",
            "NETTOYER LES CABINES DE TOILETTE",
            "NETTOYER LE SOL",
            "VERIFIER L'EXISTENCE DU PRODUIT LAVANT POUR LES MAINS",
            "VIDER LES POUBELLES",
            "Verifier Les toilettes en presence de chef d'equipe"
        ],
        ZoneA: [
            "Nettoyer a l'aide d'un produit d'entretien neutre",
            "Verifier que l'entourage du machine est propre",
            "Verifier le branchement du cable courant et cable reseau (RJ45)",
            "Verifier le fonctionnement de lecteur carte, ballons, jetons",
            "Verifier le fonctionnement avec QR et vérifier que le QR est bien lisible",
            "Nettoyer la poussière des peluches accrochés",
            "Vérifier et organiser les ballons des jeux de challenges, accessoires",
            "Nettoyer le sol",
            "Verifier le fonctionnement de l'espace",
            "Verifier le fonctionnement de machine en présence du Resp"
        ],
        ZoneB: [
            "Nettoyer a l'aide d'un produit d'entretien neutre",
            "Verifier que l'entourage du machine est propre",
            "Verifier le branchement du cable courant et cable reseau (RJ45)",
            "Verifier le fonctionnement de lecteur carte, ballons, jetons",
            "Verifier le fonctionnement avec QR et vérifier que le QR est bien lisible",
            "Verifier le fonctionnement de machine en présence du Resp"
        ]
    };

    const zoneColors = {
        Playzone: "black",
        AccueilCandy: "black",
        Toilette: "black",
        ZoneA: "black",
        ZoneB: "black"
    };

    const handleZoneClick = (zone) => {
        setSelectedZone(zone);
    };

    const handleStatusChange = (task, status) => {
        setTasksStatus((prevState) => ({
            ...prevState,
            [selectedZone]: {
                ...prevState[selectedZone],
                [task]: status
            }
        }));

        // Vérification si toutes les tâches de la zone actuelle sont complètes
        const zoneTasks = zones[selectedZone];
        const allTasksCompleted = zoneTasks.every(task => tasksStatus[selectedZone][task] === "correct" || tasksStatus[selectedZone][task] === "nonConforme");

        if (allTasksCompleted && !completedZones.includes(selectedZone)) {
            setCompletedZones((prevState) => [...prevState, selectedZone]);
        }
    };

    const handleCommentChange = (task, value) => {
        setComment((prevState) => ({
            ...prevState,
            [task]: value
        }));
    };

    const calculateZonePercentages = () => {
        const zonePercentages = {};

        Object.keys(zones).forEach(zone => {
            const tasks = zones[zone];
            const totalTasks = tasks.length;
            const conformes = tasks.filter(task => tasksStatus[zone][task] === "correct").length;
            const nonConformes = tasks.filter(task => tasksStatus[zone][task] === "nonConforme").length;

            const pourcentageConforme = ((conformes / totalTasks) * 100).toFixed(2);
            const pourcentageNonConforme = ((nonConformes / totalTasks) * 100).toFixed(2);

            zonePercentages[zone] = {
                pourcentageConforme,
                pourcentageNonConforme
            };
        });

        return zonePercentages;
    };

    const handleRedirect = () => {
        if (completedZones.length === Object.keys(zones).length) {
            const currentDate = new Date().toLocaleDateString();  // Récupère la date actuelle
            setRedirectToHome(true);
            // Passe la date et les résultats à Home
            // Exemple : Enregistrez la date et les résultats dans un état
            setCheckListResults(prevState => [
                ...prevState,
                {
                    date: currentDate,
                    results: zonePercentages  // Vous pouvez passer les résultats calculés ici
                }
            ]);
        } else {
            alert("Veuillez compléter toutes les zones avant de soumettre.");
        }
    };
    

    const handleNextZone = () => {
        const zoneKeys = Object.keys(zones);
        const currentIndex = zoneKeys.indexOf(selectedZone);
        if (currentIndex < zoneKeys.length - 1) {
            setSelectedZone(zoneKeys[currentIndex + 1]);
        } else {
            setRedirectToHome(true);
        }
    };

    const zonePercentages = calculateZonePercentages();
    const zoneKeys = Object.keys(zones);
    const isLastZone = zoneKeys.indexOf(selectedZone) === zoneKeys.length - 1;
    const buttonText = isLastZone ? "Envoyer vers Home" : "Page suivante";

       // Assurez-vous que `zonePercentages` est un objet valide
    if (redirectToHome) {
        return <Home zonePercentages={zonePercentages || {}} />;
    }
    
    

    return (
        <div className="checklist-container">
            <h2>Check-List MANAR CITY</h2>
            <div className="zone-buttons">
                {Object.keys(zones).map(zone => (
                    <button
                        key={zone}
                        onClick={() => handleZoneClick(zone)}
                        className={`zone-button ${selectedZone === zone ? 'active' : ''}`}
                        style={{ backgroundColor: selectedZone === zone ? zoneColors[zone] : '' }}
                    >
                        {zone}
                    </button>
                ))}
            </div>

            <form className="task-list">
                {zones[selectedZone].map((task, index) => (
                    <div key={index} className="task">
                        <span className="task-name">{task}</span>
                        <div className="task-buttons">
                            {tasksStatus[selectedZone][task] !== "correct" && tasksStatus[selectedZone][task] !== "nonConforme" && (
                                <>
                                    <button
                                        type="button"
                                        onClick={() => handleStatusChange(task, "correct")}
                                        className="correct-button"
                                    >
                                        Conforme
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleStatusChange(task, "nonConforme")}
                                        className="non-conforme-button"
                                    >
                                        Non Conforme
                                    </button>
                                </>
                            )}
                        </div>
                        {tasksStatus[selectedZone][task] === "correct" && (
                            <span className="confirmation">Tâche complétée Conforme</span>
                        )}
                        {tasksStatus[selectedZone][task] === "nonConforme" && (
                            <div className="comment-section">
                                <input
                                    type="text"
                                    placeholder="Explication de la défaillance"
                                    value={comment[task] || ""}
                                    onChange={(e) => handleCommentChange(task, e.target.value)}
                                />
                            </div>
                        )}
                    </div>
                ))}
                <button type="button" onClick={handleNextZone} className="submit-button">{buttonText}</button>
            </form>
        </div>
    );
}

export default CheckListMC;
