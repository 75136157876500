import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardUser from './CardUser';
import { getAllusers } from '../Redux/Actions/AuthActions';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Register from './Register';

const ListUser = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllusers());
  }, [dispatch]);

  const Users = useSelector((state) => state.AuthReducer.Users);

  return (
    <Container className="my-4">
      <Register/>
      <Row className="g-4">
        {Users &&
          Users.map((el) => (
            <Col key={el.id} xs={12} sm={6} md={4} lg={3}>
              <CardUser el={el} />
            </Col>
          ))}
      </Row>
      
      
    </Container>
  );
};

export default ListUser;
