import { GETALLQUIZQUESTIONS, GETALLQUIZS, GETMYQUIZS, GETONEQUIZ, GETQUIZGROUPED } from "../ActionTypes/QuizTypes"

const initialState = {
    Quizs : [],
    oneQuiz : {},
    Questions : [],
    myQuizs : [],
    quizGrouped : []

}
const QuizReducer=(state=initialState, action) =>{
switch (action.type) {
    case GETALLQUIZS : return {...state,Quizs : action.payload}
    case GETONEQUIZ : return {...state, oneQuiz : action.payload}
    case GETALLQUIZQUESTIONS : return {...state, Questions : action.payload}
    case GETMYQUIZS : return {...state, myQuizs : action.payload}
    case GETQUIZGROUPED : return {...state, quizGrouped : action.payload}
    default: return state
}
}

export default QuizReducer