import React, { useEffect, useState } from 'react';
import { current } from '../Redux/Actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import { addResponses, getAllQuizQuestions, getOneQuiz } from '../Redux/Actions/QuizActions';
import { useNavigate, useParams } from 'react-router-dom';
import './ReponseQuiz.css';

const ReponseQuiz = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(current());
    dispatch(getOneQuiz(id));
    dispatch(getAllQuizQuestions(id));
  }, []);

  const user = useSelector((state) => state.AuthReducer.user);
  const oneQuiz = useSelector((state) => state.QuizReducer.oneQuiz);
  const Questions = useSelector((state) => state.QuizReducer.Questions);

  const [reponses, setReponses] = useState([]);

  useEffect(() => {
    setReponses(
      Questions.map((el) => ({
        departement: el.departement,
        objet: el.objet,
        question: el._id,
        status: null, // Statut initial : null
        explanation: '',
        showExplanationInput: false, // Contrôle du champ d'explication
      }))
    );
  }, [Questions]);

  const navigate = useNavigate();

  const handleConforme = (question) => {
    setReponses(
      reponses.map((res) =>
        res.question === question
          ? { ...res, status: true, showExplanationInput: false, explanation: '' }
          : res
      )
    );
  };

  const handleNonConforme = (question) => {
    setReponses(
      reponses.map((res) =>
        res.question === question
          ? { ...res, showExplanationInput: true }
          : res
      )
    );
  };

  const handleExplanationChange = (question, value) => {
    setReponses(
      reponses.map((res) =>
        res.question === question
          ? { ...res, explanation: value }
          : res
      )
    );
  };

  const confirmNonConforme = (question) => {
    const response = reponses.find((res) => res.question === question);
    if (response.explanation.trim().length > 1) {
      setReponses(
        reponses.map((res) =>
          res.question === question
            ? { ...res, status: false, showExplanationInput: false }
            : res
        )
      );
    } else {
      alert('L’explication doit contenir au moins deux caractères.');
    }
  };

  // Fonction pour obtenir la date du jour courant au format souhaité
  const getCurrentDate = () => {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Mois commence à 0
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="reponse-quiz-container">
      {oneQuiz && (
        <div>
          <h1 className="quiz-title">{oneQuiz.title}</h1>
          <h2 className="quiz-location">{oneQuiz.localisation}</h2>
        </div>
      )}
      <div className="confirmation-date">
        <strong>Date du jour : </strong>
        {getCurrentDate()}
      </div>
      <ul className="questions-list">
        {reponses &&
          reponses.map((el, i) => (
            <li key={i} className="question-item">
              <div className="question-header">
                <span>{el.objet}</span>
              </div>
              <div className="question-details">
                <div className="question-departement">
                  <strong>Département:</strong> {el.departement}
                </div>
                <div className="question-status">
                  <strong>Statut:</strong>{' '}
                  {el.status === null
                    ? 'Statut par défaut'
                    : el.status === true
                    ? 'Conforme'
                    : `Non Conforme: ${el.explanation}`}
                </div>
              </div>
              {el.status === null && (
                <div className="question-actions">
                  <button
                    className="action-button conforme"
                    onClick={() => handleConforme(el.question)}
                  >
                    Conforme
                  </button>
                  <button
                    className="action-button non-conforme"
                    onClick={() => handleNonConforme(el.question)}
                  >
                    Non Conforme
                  </button>
                </div>
              )}
              {el.showExplanationInput && (
                <div className="explanation-section">
                  <textarea
                    className="explanation-input"
                    placeholder="Ajouter une explication..."
                    value={el.explanation}
                    onChange={(e) => handleExplanationChange(el.question, e.target.value)}
                  />
                  <button
                    className="action-button confirm-non-conforme"
                    onClick={() => confirmNonConforme(el.question)}
                  >
                    Confirmer Non Conforme
                  </button>
                </div>
              )}
            </li>
          ))}
      </ul>
      <button
        className="confirm-button"
        onClick={(e) => {
          e.preventDefault();
          dispatch(addResponses(user._id, id, reponses, navigate, getCurrentDate())); // Ajout de la date du jour courant lors de l'envoi des réponses
        }}
      >
        Confirmation
      </button>
    </div>
  );
};

export default ReponseQuiz;
