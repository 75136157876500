import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './HumanResources.css';
import { current, getAllusers } from "../Redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { addHR, getHR } from '../Redux/Actions/HumainRessourcesActions';

const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
const creneaux = [
  { label: "08h - 10h", heures: 2 },
  { label: "10h - 12h", heures: 2 },
  { label: "12h - 14h", heures: 2 },
  { label: "14h - 16h", heures: 2 },
  { label: "16h - 17h", heures: 1 },
  { label: "17h - 18h", heures: 1 },
  { label: "18h - 19h", heures: 1 },
  { label: "19h - 20h", heures: 1 },
  { label: "20h - 21h", heures: 1 },
  { label: "21h - 22h", heures: 1 },
  { label: "22h - 23h", heures: 1 }
];

function HumanResources() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(current());
    dispatch(getAllusers());
    dispatch(getHR());
  }, [dispatch]);

  const user = useSelector(state => state.AuthReducer.user);
  const Users = useSelector(state => state.AuthReducer.Users);
  const humanRessources = useSelector((state) => state.HumanRessourcesReducer.humanRessources);


  const [emploisDuTemps, setEmploisDuTemps] = useState({});
  const [selectedEmploye, setSelectedEmploye] = useState(null);
  const [salaireMensuel, setSalaireMensuel] = useState(0);
  const [destinataire, setDestinataire] = useState([]);
  const [celluleCliquee, setCelluleCliquee] = useState({});
  const [owner, setOwner] = useState("");
  const [debut, setDebut] = useState("");
  const [fin, setFin] = useState("");
  const [datesRange, setDatesRange] = useState([]);
  const [emploiCalcul, setEmploiCalcul] = useState({});

  useEffect(() => {
    if (Users) {
      const initialEmplois = {};
      Users.forEach(user => {
        initialEmplois[user._id] = Array(jours.length).fill().map(() => Array(creneaux.length));
      });
      setEmploisDuTemps(initialEmplois);
    }
  }, [Users]);

  const toggleCouleurCellule = (jourIndex, creneauIndex) => {
    if (selectedEmploye) {
      const celluleKey = `${selectedEmploye}-${jourIndex}-${creneauIndex}`;
      setCelluleCliquee((prevCelluleCliquee) => ({
        ...prevCelluleCliquee,
        [celluleKey]: !prevCelluleCliquee[celluleKey],
      }));
    }
  };

  // Calculer la date de fin automatiquement
  useEffect(() => {
    if (debut) {
      const startDate = new Date(debut);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6); // Ajouter 6 jours pour avoir une durée totale de 7 jours
  
      const formattedDate = `${String(endDate.getDate()).padStart(2, '0')}/${String(endDate.getMonth() + 1).padStart(2, '0')}/${endDate.getFullYear()}`;
      setFin(formattedDate);

      const dates = [];
      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const formattedDate = `${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}/${d.getFullYear()}`;
        dates.push(formattedDate);
      }
      setDatesRange(dates);
    }
  }, [debut]);
  
  const handleDateChange = (event) => {
    const date = new Date(event.target.value);
    const dayOfWeek = date.getUTCDay();

    if (dayOfWeek === 1) { // Lundi
      setDebut(event.target.value);
    } else {
      alert("Veuillez sélectionner un lundi.");
      setDebut("");
    }
  };

  const handleSubmit = () => {
    dispatch(addHR({ 
      salaireMensuel, 
      emploisDuTemps: celluleCliquee, 
      owner, 
      debut, 
      fin,
      emploiCalcul: calculerHeuresParJour(celluleCliquee, datesRange), 
      datesRange 
    }));
    
    // Réinitialiser les états après l'envoi
    setSalaireMensuel(0);
    setEmploisDuTemps({});
    setSelectedEmploye(null);
    setDestinataire([]);
    setCelluleCliquee({});
    setOwner("");
    setDebut("");
    setFin("");
    setDatesRange([]);
    setEmploiCalcul({});
  };
  
  function calculerHeuresParJour(emploi, rangeDate) {
    const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
    const heuresSlots = [
      "08:00", "10:00", "12:00", "14:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"
    ];

    const resultat = {};

    for (let slot in emploi) {
      if (emploi[slot]) {
        const [id, jourIndex, heureIndex] = slot.split('-').map(Number);
        const jour = jours[jourIndex];

        if (!resultat[jour]) {
          resultat[jour] = {
            date: rangeDate[jourIndex] || null,
            totalHeures: 0,
            debut: null,
            fin: null
          };
        }

        const heuresTravaillees = (heureIndex >= 4 && heureIndex <= 10) ? 1 : 2;
        resultat[jour].totalHeures += heuresTravaillees;

        if (resultat[jour].debut === null || heureIndex < resultat[jour].debut) {
          resultat[jour].debut = heureIndex;
        }

        if (resultat[jour].fin === null || heureIndex > resultat[jour].fin) {
          resultat[jour].fin = heureIndex;
        }
      }
    }

    for (let jour in resultat) {
      resultat[jour].debut = heuresSlots[resultat[jour].debut] || null;
      resultat[jour].fin = heuresSlots[resultat[jour].fin + 1] || null;
    }

    return resultat;
  }

  const [verif , setVerif] = useState(false)
  function checkOwnerAndStartDate(data, ownerId, startDate) {
    console.log(data)
    console.log('ffff', data.some(item => item.owner?._id === ownerId && item.debut === startDate.toString()))
    setVerif(data.some(item => item.owner?._id === ownerId && item.debut === startDate.toString())) 
  }

  useEffect(()=>{

    console.log('dest',destinataire)
    checkOwnerAndStartDate(humanRessources,owner,debut)
    console.log(verif)
  },[owner,debut,humanRessources])
  return (
    <div className="human-resources-container">
      <h3>Gestion des Emplois du Temps des Employés</h3>

      <h5>Nom de l'employé</h5>
      <Form.Select 
  aria-label="Choisir un destinataire" 
  onChange={(e) => {
    const employeId = e.target.value;
    setSelectedEmploye(employeId); 
    setOwner(employeId);
    
    // Trouver l'utilisateur sélectionné et définir le salaire
    const employe = Users.find(user => user?._id === employeId);
    if (employe) {
      setSalaireMensuel(employe.salaire || 0); // Assurez-vous que "salaire" existe dans les données
    }
  }} 
  value={selectedEmploye || ""}
>
  <option value="" disabled>Choisissez un employé</option>
  {Users.map((user) => (
    <option key={user._id} value={user._id}>
      {user.name} 
    </option>
  ))}
</Form.Select>


      {selectedEmploye && (
        <div>
          <h4>Emploi du Temps de {Users.find(user => user._id === selectedEmploye)?.name}</h4>

          <Form>
  <div className="form-row">
  <Form.Group>
  <Form.Label>Salaire Horaire</Form.Label>
  <Form.Control
    type="number"
    value={(salaireMensuel / 208).toFixed(3)} // Arrondi à 3 chiffres après la virgule
    readOnly // Empêche l'édition manuelle
  />
</Form.Group>


    <Form.Group className="form-item">
      <Form.Label>Date de début</Form.Label>
      <Form.Control
        type="date"
        value={debut}
        onChange={handleDateChange}
      />
    </Form.Group>

    <Form.Group className="form-item">
      <Form.Label>Date de fin</Form.Label>
      <Form.Control
        type="text"
        value={fin}
        readOnly
      />
    </Form.Group>
  </div>
</Form>

          <table border="1">
            <thead>
              <tr>
                <th></th>
                {creneaux.map((creneau, index) => (
                  <th key={index}>{creneau.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {jours.map((jour, jourIndex) => (
                <tr key={jourIndex}>
                  <td>{jour}</td>
                  {creneaux.map((_, creneauIndex) => (
                    <td
                      key={creneauIndex}
                      onClick={() => toggleCouleurCellule(jourIndex, creneauIndex)}
                      style={{
                        backgroundColor: celluleCliquee[`${selectedEmploye}-${jourIndex}-${creneauIndex}`]
                          ? "lightgreen"
                          : "white",
                      }}
                    ></td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
{
  verif ? <h2>"Un emploi existe déja"</h2> :  <Button onClick={handleSubmit}>Envoyer l'emploi</Button>
}
         
        </div>
      )}
    </div>
  );
}

export default HumanResources;
