import { CURRENT, FAIL, GETALLUSERS, GETONEUSER, LOGIN, LOGOUT, REGISTER } from "../ActionTypes/AuthTypes"

const initialState = {
user : {}, 
errors : [],
Users : [],
upUser : {}


}

const AuthReducer=(state = initialState,action)=>{
switch (action.type) {

    case REGISTER : 
    localStorage.setItem('token', action.payload.token)
    return {...state, user : action.payload.newAccount, errors: []}
   
    case LOGIN : 
    localStorage.setItem('token', action.payload.token)
    return {...state,user : action.payload.found, errors : []}

    case LOGOUT : 
    localStorage.removeItem('token')
    return {...state, user : {}, errors: []}

    case CURRENT : return{...state, user : action.payload}

    case FAIL : return{...state, errors : action.payload}

    case GETALLUSERS : return {...state, Users : action.payload}

    case GETONEUSER : return {...state, upUser : action.payload }

    default: return state
}

}

export default AuthReducer