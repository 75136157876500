import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { current, getAllusers } from '../Redux/Actions/AuthActions';
import { addOvertimeRequest } from '../Redux/Actions/OvertimeActions';
import { Container, Spinner } from 'react-bootstrap';
import './GestionHeuresSupp.css';
import ReponseHS from './ReponseHS';

function GestionHeuresSupp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Sélection des utilisateurs et des demandes d'heures supplémentaires
  const user = useSelector((state) => state.AuthReducer.user || {}); // Utilisateur connecté
  const overtimeRequests = useSelector((state) => state.OvertimeRequestReducer.overtimeRequests || []);
  const loading = useSelector((state) => state.AuthReducer.loading || false); // Charge l'état de l'utilisateur
  
  const [newRequest, setNewRequest] = useState({
    date: '',
    startHour: '',
    endHour: '',
  });

  // Charger les utilisateurs et les informations utilisateur courantes au montage
  useEffect(() => {
    dispatch(current()); // Récupère l'utilisateur connecté
    dispatch(getAllusers()); // Récupère tous les utilisateurs (au cas où vous en avez besoin)
  }, [dispatch]);

  // Redirection si une demande en attente existe pour l'employé connecté
  useEffect(() => {
    if (overtimeRequests.some((req) => req.employeId === user._id && req.status === 'pending')) {
      navigate('/GestionHeuresSupp');
    }
  }, [overtimeRequests, user._id, navigate]);

  // Gestion de l'ajout d'une demande
  const handleAddRequest = async () => {
    const { date, startHour, endHour } = newRequest;

    if (date && startHour && endHour) {
      const request = {
        employeId: user._id, // Utiliser l'ID de l'utilisateur connecté
        date,
        startHour,
        endHour,
        status: 'pending', // Le statut est 'pending' par défaut
      };

      try {
        await dispatch(addOvertimeRequest(request));
        setNewRequest({ date: '', startHour: '', endHour: '' });
        alert('Demande envoyée pour validation.');
      } catch (error) {
        console.error('Erreur lors de l\'ajout de la demande :', error);
        alert('Erreur lors de l\'envoi de la demande.');
      }
    } else {
      alert('Veuillez remplir tous les champs.');
    }
  };

  // Vérifier si l'utilisateur est en train de se charger (indicateur de chargement)
  if (loading) {
    return (
      <Container className="container mt-4">
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Chargement...</span>
          </Spinner>
        </div>
      </Container>
    );
  }

  return (
    <Container className="container mt-4">
      <div className="overtime-container">
        <h3 className="overtime-title">Gestion des Heures Supplémentaires</h3>
        <div className="overtime-section">
          <h5>Ajouter une demande d'heures supplémentaires</h5>
          
          {/* Vérification de l'existence de l'utilisateur */}
          {user && user.name ? (
            <p>Employé connecté : <strong>{user.name}</strong></p>
          ) : (
            <p>Chargement de l'utilisateur...</p>
          )}

          {/* Formulaire pour ajouter une demande d'heures supplémentaires */}
          <div className="form-group">
            <input
              type="date"
              value={newRequest.date}
              onChange={(e) => setNewRequest({ ...newRequest, date: e.target.value })}
              className="form-control"
            />
            
            <div className="form-time">
              <label>Heure de début</label>
              <input
                type="time"
                value={newRequest.startHour}
                onChange={(e) => setNewRequest({ ...newRequest, startHour: e.target.value })}
                className="form-control"
              />
              
              <label>Heure de fin</label>
              <input
                type="time"
                value={newRequest.endHour}
                onChange={(e) => setNewRequest({ ...newRequest, endHour: e.target.value })}
                className="form-control"
              />
            </div>
            
            <button onClick={handleAddRequest} className="btn btn-primary">
              Envoyer la demande
            </button>
          </div>
        </div>
      </div>
      
      <ReponseHS/>
    </Container>
  );
}

export default GestionHeuresSupp;
