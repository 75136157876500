import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

function PdfDetails({el}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
     <div style={{color:"rebeccapurple"}} variant="primary" onClick={handleShow}>
  <FontAwesomeIcon icon={faFilePdf} />PDF
</div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>PDF's</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
             el.file.map((el,i,t)=> 
             <>
              <iframe src={`/uploads/${el}`}/>
              <a href={`/uploads/${el}`} download>
        <button>Download PDF</button>
      </a>
             </>
            )
          }
            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PdfDetails;