// Redux/Actions/NotificationActions.js
export const addNotification = (notification) => {
    return {
        type: 'ADD_NOTIFICATION',
        payload: notification
    };
};

export const setNotificationCount = (count) => ({
    type: 'SET_NOTIFICATION_COUNT',
    payload: count,
  });