import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { current, getAllusers } from "../Redux/Actions/AuthActions";
import { addRequest } from "../Redux/Actions/RequestActions";
import './AddRequest.css';
import Webcam from "react-webcam";

const AddRequests = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(current());
    dispatch(getAllusers());
  }, []);

  const user = useSelector(state => state.AuthReducer.user);
  const Users = useSelector(state => state.AuthReducer.Users);

  const [objet, setObjet] = useState('');
  const [descriptif, setDescriptif] = useState('');
  const [destinataire, setDestinataire] = useState([]);
  const [localisation, setLocalisation] = useState('');
  const [service, setService] = useState('');
  const [availableObjects, setAvailableObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState('');
  const [send, setSend] = useState(false); 
  const [file, setFile] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("For you Today");
  const [image,setImage]=useState([])  
  const [affImage,setAffImage] = useState([])
  const [chooseCam,setChooseCam] = useState(true)


  const navigate = useNavigate();

  // Liste des services par catégorie
  const servicesByCategory = {
    "For you Today": [
    { name: "Achats", objects: ["Fournitures de Bureau", "Candy Bar", "Jouets pour Boutique", "Produits nettoyages", "Pièces de rechanges", "Produits pour Restaurant", "Recharge Balles Gifty"] },
    { name: "Maintenance", objects: ["Reparation PlayZone", "Machine de jeux", "Matériel Informatique", "Matériel Restaurant", "Plomberie et Electricité", "Autres travaux de Mise à Niveau"] },
    { name: "Paiements", objects: ["Recette des Finances", "Règlement fournisseurs", "CNSS", "Paiements en ligne"] },
    { name: "Transferts", objects: ["Fauteuils de Massage", "Machines de Jeux", "Autres Materiel", "Articles Candy Bar", "Articles de Jouets"] },
    { name: "Ressources Humaines", objects: ["Autorisation de Sortie", "Demande de Congé", "Demande d'Avance", "Attestation de Travail", "Fiche de Paie", "Certificat d'impôt"] },
    { name: "Reporting", objects: ["Rapport financier", "Recettes Journalières Park", "Saisie de Collecte Parks", "Recette Collecte Fauteuils de Massage"] },
    { name: "CheckList", objects: ["CheckList Playzone", "CheckList Machines", "CheckList Fauteuil de Massage"] },
    ],
    "Repetitive Tasks": [
      { name: "Achats", objects: ["Fournitures de Bureau", "Candy Bar", "Jouets pour Boutique", "Produits nettoyages", "Pièces de rechanges", "Produits pour Restaurant", "Recharge Balles Gifty"] },
    { name: "Maintenance", objects: ["Reparation PlayZone", "Machine de jeux", "Matériel Informatique", "Matériel Restaurant", "Plomberie et Electricité", "Autres travaux de Mise à Niveau"] },
    { name: "Paiements", objects: ["Recette des Finances", "Règlement fournisseurs", "CNSS", "Paiements en ligne"] },
    { name: "Transferts", objects: ["Fauteuils de Massage", "Machines de Jeux", "Autres Materiel", "Articles Candy Bar", "Articles de Jouets"] },
    { name: "Ressources Humaines", objects: ["Autorisation de Sortie", "Demande de Congé", "Demande d'Avance", "Attestation de Travail", "Fiche de Paie", "Certificat d'impôt"] },
    { name: "Reporting", objects: ["Rapport financier", "Recettes Journalières Park", "Saisie de Collecte Parks", "Recette Collecte Fauteuils de Massage"] },
    { name: "CheckList", objects: ["CheckList Playzone", "CheckList Machines", "CheckList Fauteuil de Massage"] },
    ],
    "New Project": [
      { name: "Achats", objects: ["Fournitures de Bureau", "Candy Bar", "Jouets pour Boutique", "Produits nettoyages", "Pièces de rechanges", "Produits pour Restaurant", "Recharge Balles Gifty"] },
      { name: "Maintenance", objects: ["Reparation PlayZone", "Machine de jeux", "Matériel Informatique", "Matériel Restaurant", "Plomberie et Electricité", "Autres travaux de Mise à Niveau"] },
      { name: "Paiements", objects: ["Recette des Finances", "Règlement fournisseurs", "CNSS", "Paiements en ligne"] },
      { name: "Transferts", objects: ["Fauteuils de Massage", "Machines de Jeux", "Autres Materiel", "Articles Candy Bar", "Articles de Jouets"] },
      { name: "Ressources Humaines", objects: ["Autorisation de Sortie", "Demande de Congé", "Demande d'Avance", "Attestation de Travail", "Fiche de Paie", "Certificat d'impôt"] },
      { name: "Reporting", objects: ["Rapport financier", "Recettes Journalières Park", "Saisie de Collecte Parks", "Recette Collecte Fauteuils de Massage"] },
      { name: "CheckList", objects: ["CheckList Playzone", "CheckList Machines", "CheckList Fauteuil de Massage"] },
    ],
  };

  const filteredServices = servicesByCategory[selectedCategory];

  const handleRequest = (e) => {
    e.preventDefault();
    console.log("image",image)
    console.log("file",file)
    if (destinataire.length === 0) {
      alert('Choisissez un destinataire');
      return;
    }

    if (!send) {
      setSend(true);
      dispatch(addRequest({
        objet,
        descriptif,
        destinataire: destinataire.map((el) => el.id),
        owner: user._id,
        localisation,
        service,
        validation: destinataire.map((el) => ({ user: el.id, status: "In progress" })),
        image,
        file,
        selectedCategory
      }, navigate));
    }
  };

  const handleServiceChange = (e) => {
    const selectedService = e.target.value;
    setService(selectedService);
    const foundService = filteredServices.find((s) => s.name === selectedService);
    if (foundService) {
      setAvailableObjects(foundService.objects);
    } else {
      setAvailableObjects([]);
    }
    setSelectedObject('');
  };

  // Convertion Image from base 64 to File
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };
  


const convertionFile=async(e,imageSrc,name)=>{
  e.preventDefault()
  const response = await fetch(imageSrc);
  const blob = await response.blob();
  const newI =  new File([blob], `${name}.png`, { type: blob.type });
  setAffImage([...affImage,URL.createObjectURL(newI)])
  console.log("Nouvelle Image", newI)
 await  setImage([...image,newI])
  console.log("Tableau : ",image)
}



const handleObjectChange = (e) => {
  setObjet(e.target.value);
  setSelectedObject(e.target.value);
};
const handleImageDelete = (index) => {
  setImage(image.filter((_, i) => i !== index));
  setAffImage(affImage.filter((_, i) => i !== index));
};



  return (
    <Form className="add-request-container">
      <div className="category-buttons">
        {["For you Today", "Repetitive Tasks", "New Project"].map((category) => (
          <Button
            key={category}
            variant="outline-primary"
          className={`category-button ${selectedCategory === category ? "active" : ""} mx-2`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </Button>
        ))}
      </div>

      {/* Section Services */}
      <Form.Group className="mb-3">
        <Form.Label >Services</Form.Label>
        <Form.Select onChange={handleServiceChange} value={service} aria-label="Sélectionnez un service">
          <option value="">Services</option>
          {filteredServices.map((service, index) => (
            <option key={index} value={service.name}>
              {service.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Section Objet */}
      <Form.Group className="mb-3">
        <Form.Label>Objet</Form.Label>
        <Form.Select onChange={handleObjectChange} value={selectedObject} aria-label="Sélectionner un objet">
          <option value="">Sélectionner un objet</option>
          {availableObjects.map((obj, index) => (
            <option key={index} value={obj}>
              {obj}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      {/* Autres champs du formulaire */}
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control onChange={(e) => setDescriptif(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Localisation</Form.Label>
        <Form.Select onChange={(e) => setLocalisation(e.target.value)} aria-label="Sélectionnez une localisation">
          <option>Localisation</option>
          <option value="TP-Manar City">TP-Manar City</option>
          <option value="TP-Tunisia Mall">TP-Tunisia Mall</option>
          <option value="TP-Jardins d'El Menzah">TP-Jardins d'El Menzah</option>
          <option value="JZ-Djerba Mall">JZ-Djerba Mall</option>
          <option value="Spintoss-Les Jardins">Dépôt</option>
          <option value="Administration">Administration</option>
        </Form.Select>

        
      </Form.Group>
      <div className="image-container">
        {affImage.map((src, i) => (
          <div key={i} className="image-wrapper">
            <img src={src} alt={`image-${i}`} className="image-preview" />
            <span 
              className="delete-icon" 
              onClick={() => handleImageDelete(i)}
            >
              ✖
            </span>
          </div>
        ))}
      </div>

      {/* Ajouter le style pour la croix de suppression */}
      
      <style>
        {`
          .image-container {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
          }

          .image-wrapper {
            position: relative;
            width: 100px;
            height: 100px;
          }

          .image-preview {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }

          .delete-icon {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
            font-size: 18px;
            color: red;
            background-color: white;
            border-radius: 50%;
            padding: 0 5px;
            visibility: hidden;
          }

          .image-wrapper:hover .delete-icon {
            visibility: visible;
          }
        `}
      </style>


    {
      image.length<5 &&
       <Button onClick={() => setChooseCam(!chooseCam)}>
      {chooseCam ? "Choose WebCam" : "Join File"}
    </Button>
    }
    
      {
        chooseCam ? 
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            {
              image.length < 5 &&
              <>
              <Form.Label>Images</Form.Label>
              <Form.Control 
                type="file" 
                multiple 
                onChange={(e) => {
                  console.log(e.target.files[0])
                  var a = Array.from(e.target.files).map(el=> URL.createObjectURL(el))
                   setAffImage([...affImage,... a])
                  console.log(a)
                  setImage([...image,...e.target.files])
                }} 
                
              />
              </>
            }
          
          </Form.Group>
        :
      
          <Webcam
    audio={false}
    height={300}
    screenshotFormat="image/jpeg"
    width={300}
    videoConstraints={videoConstraints}
  >
    {({ getScreenshot }) => (
      <>
      {
        image.length < 5 &&
        <div>
        <button 
        onClick={(e) => {
          convertionFile(e,getScreenshot(), `${Math.random()}`)       
        }}
      >
        Prendre une photo
      </button>
      </div>
      }
    
      </>
    )}
  </Webcam>
      }

      <Form.Group className="mb-6">
        <Form.Label>Ajouter des fichiers</Form.Label>
        <Form.Control 
          type="file" 
          multiple 
          onChange={(e) => {
            e.preventDefault()
           setFile([...file,...e.target.files])}} 
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Destinataire</Form.Label>
        {destinataire.map((el, i) => (
          <div key={i} className="destinataire-item">
            <h5>{el.name}</h5>
            <Button variant="danger" onClick={() => setDestinataire(destinataire.filter((del) => del.id !== el.id))}>
              Supprimer
            </Button>
          </div>
        ))}
        <Form.Select className="mb-4" onChange={(e) => setDestinataire([...destinataire, { id: e.target.value, name: Users.find(el => el._id === e.target.value).name }])} aria-label="Choisir un destinataire">
          <option>Choisir un destinataire</option>
          {Users.filter((el) => el._id !== user._id).map((el, i) => (
            <option key={i} value={el._id}>{el.name}</option>
          ))}
        </Form.Select>
      </Form.Group>

      <Button variant="primary" onClick={(e)=>handleRequest(e)}>Envoyer</Button>
    </Form>
  );
};

export default AddRequests;
