import React from 'react'
import logo from "../image/flow.png";
import { Container } from 'react-bootstrap';

const Welcome = () => {
  return (
    <Container>
    <div>
       <h1>Welcome to  <img src={logo} alt="Mon logo"  style={{ width: '1200px' }} /> </h1>
    </div>
    </Container>
  )
}

export default Welcome
