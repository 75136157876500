import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate, useParams } from 'react-router-dom';
import { getOneUser, updateUser } from '../Redux/Actions/AuthActions';

const UpdateUser = () => {

    const{id}=useParams()

    const dispatch =useDispatch()

    useEffect(()=>{

      dispatch(getOneUser(id))

    },[])

    const upUser = useSelector(state=> state.AuthReducer.upUser)

    const [name, setName]= useState(upUser.name)
    const [email, setEmail]= useState(upUser.email)
    const [matricule, setMatricule]= useState(upUser.matricule)
    const [localisation, setLocalisation]= useState(upUser.localisation)
    const [salaire, setSalaire]=useState(upUser.salaire)
    const [phone, setPhone]=useState(upUser.phone)
    
       
    useEffect(()=>{
      setName(upUser.name)
      setEmail(upUser.email)
      setMatricule(upUser.matricule)
      setLocalisation(upUser.localisation)
      setSalaire(upUser.salaire)
      setPhone(upUser.phone)
    },[upUser])

    const navigate = useNavigate()
    const handleUpdate=(a)=>{
      a.preventDefault()
      dispatch(updateUser(id,{name,email,matricule,localisation, salaire,phone},navigate))
    }

  return (
    <Form>

    <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control value={name}  onChange ={(e) => setName(e.target.value)} type="name" placeholder="Enter Name" />
        </Form.Group>
    
        <Form.Group className="mb-3">
          <Form.Label>Email Adress</Form.Label>
          <Form.Control value={email}  onChange ={(e) => setEmail(e.target.value)} type="email" placeholder="Enter email" />
        </Form.Group>
    
        <Form.Group className="mb-3">
        <Form.Label>Matricule</Form.Label>
          <Form.Control value={matricule}  onChange ={(e) => setMatricule(e.target.value)} type="matricule" placeholder="Enter votre matricule" />
        </Form.Group>
    
        <Form.Group className="mb-3">
          <Form.Label>Localisation</Form.Label>
          <Form.Control value={localisation}  onChange ={(e) => setLocalisation(e.target.value)} type="localisation" placeholder="localisation" />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Salaire</Form.Label>
          <Form.Control value={salaire}  onChange ={(e) => setSalaire(e.target.value)} type="salaire" placeholder="salaire" />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Numero de téléphone</Form.Label>
          <Form.Control value={phone}  onChange ={(e) => setPhone(e.target.value)} type="phone" placeholder="Numero de téléphone" />
        </Form.Group>
      
        <Button onClick={(e)=>handleUpdate(e)} variant="primary" type="submit">
          Submit
        </Button>
      </Form>
   
  )

}
export default UpdateUser
