import React, { useEffect } from 'react';
import { getMyQuizs } from '../Redux/Actions/QuizActions';
import { useDispatch, useSelector } from 'react-redux';
import { current } from '../Redux/Actions/AuthActions';
import { Link } from 'react-router-dom';
import './MyQuizs.css'; // Import du CSS

const MyQuizs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(current());
    dispatch(getMyQuizs());
  }, []);

  const user = useSelector((state) => state.AuthReducer.user);
  const myQuizs = useSelector((state) => state.QuizReducer.myQuizs);

  return (
    <div className="my-quizs-container">
      <h1 className="my-quizs-title">Mes fiches</h1>
      <div className="quiz-list">
        {myQuizs.map((el, i) => (
        <div key={i} className="quiz-item">
        <Link to={`/ReponseQuiz/${el._id}`} className="quiz-title">
          {el.title}
        </Link>
        <h3 className="quiz-location">{el.localisation}</h3>
        <ul className="destinataires-list">
          {el.destinataires.map((destt, j) => (
            <li key={j} className="destinataire-item">
              {destt.name}
            </li>
          ))}
        </ul>
      </div>
        ))}
      </div>
    </div>
  );
};

export default MyQuizs;
