import { CLEARERROR, HANDLEERRROR } from "../ActionTypes/ErrorsTypes"

export const handleerror=(msg)=>(dispatch)=>{
const id = Math.random()

dispatch(
{
    type : HANDLEERRROR,
    payload :{msg, id}
}
)
setTimeout(()=>{
    dispatch(
        {
            type: CLEARERROR,
            payload : id
        }
    )
},3000)
}