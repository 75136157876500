
import{ GETTWINRAPPORT} from "../ActionTypes/RapportTwin"

const initialState = {
    rapportsTwin : []

}
const RapportTwinReducer=(state=initialState, action) =>{
switch (action.type) {
    case GETTWINRAPPORT : return {...state, RapportsTwin : action.payload}

    default: return state
}
}



export default RapportTwinReducer