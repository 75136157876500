import { GETHR, GETMYHR,UPDATE_HR_SUCCESS, DELETE_HR } from "../ActionTypes/HumainRessourcesTypes"
import axios from 'axios'

export const getHR= ()=>async(dispatch)=>{
    try {
       const res = await axios.get('/api/HumaRessources/getHR')
    
       dispatch(
    {
        type : GETHR,
        payload : res.data.HR
    }
    
       )
    
    } catch (error) {
        console.log(error)
    }
       
    }

    export const getMyHR= (id)=>async(dispatch)=>{
        try {
           const res = await axios.get(`/api/HumaRessources/GetHRbyOwner/${id}`)
        
           dispatch(
        {
            type : GETMYHR,
            payload : res.data.MYHR
        }
        
           )
        
        } catch (error) {
            console.log(error)
        }
           
        }

    export const addHR=(newHR)=>async(dispatch)=>{
    
        try {
            console.log(newHR)
           
            await axios.post('/api/HumaRessources/AddHR',newHR)
     
            dispatch(getHR())
     
           
        } catch (error) {
            console.log(error)
        }
     }

    // Redux/Actions/HumainRessourcesActions.js
export const updateHR = (HRId, updatedSchedule) => async (dispatch) => {
  try {
    const response = await axios.patch(`/api/HumaRessources/UpdateHR`, {
      HRId,
      emploiDuTemps: updatedSchedule,
    });
    dispatch({ type: 'UPDATE_HR_SUCCESS', payload: response.data.HR });
  } catch (error) {
    console.error("Error updating HR:", error);
  }
};

    
    // Action pour supprimer un emploi du temps
    export const deleteHR = (HRId) => async (dispatch) => {
      try {
        const response = await axios.get(`/api/HumaRessources/deleteHR`, 
          { params: { HRId } } // Envoi de l'ID comme paramètre
        );
    
        dispatch({ 
          type: DELETE_HR, 
          payload: HRId // Envoi de l'ID supprimé comme payload
        });
      } catch (error) {
        console.error("Erreur lors de la suppression de l'emploi du temps :", error);
      }
    };