import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOvertimeRequests } from '../Redux/Actions/OvertimeActions';
import { Container, Table, Spinner } from 'react-bootstrap';
import './ReponseHS.css';

function ReponseHS() {
  const dispatch = useDispatch();

  // Sélectionner les données dans le state Redux
  const { overtimeRequests = [], loading } = useSelector((state) => state.OvertimeRequestReducer);
  const currentUser = useSelector((state) => state.AuthReducer.user); // Utilisateur connecté
  const salaireMensuel = currentUser?.salaire || 0; // Salaire mensuel de l'utilisateur

  // Charger les demandes d'heures supplémentaires au montage du composant
  useEffect(() => {
    dispatch(getAllOvertimeRequests());
  }, [dispatch]);

  // Filtrer les demandes pour ne conserver que celles du mois courant et de l'utilisateur connecté
  const filterRequestsForCurrentUser = () => {
    const currentMonth = new Date().getMonth(); // Mois courant (0 pour janvier, 11 pour décembre)
    const currentYear = new Date().getFullYear(); // Année courante

    return overtimeRequests.filter((request) => {
      const requestDate = new Date(request.date); // Crée un objet Date à partir de la date de la demande
      return (
        requestDate.getMonth() === currentMonth &&
        requestDate.getFullYear() === currentYear &&
        request.employeId === currentUser._id // Filtrer par utilisateur connecté
      );
    });
  };

  // Fonction pour calculer les heures supplémentaires
  const calculateOvertimeAmount = (startHour, endHour) => {
    const start = new Date(`2023-01-01T${startHour}:00`);
    const end = new Date(`2023-01-01T${endHour}:00`);
    const diff = (end - start) / 1000 / 60 / 60; // Différence en heures
    const overtimeHours = diff > 0 ? diff : 0;

    const hourlyWage = salaireMensuel / 208; // Salaire horaire basé sur 208 heures par mois
    return overtimeHours * hourlyWage * 1.75; // Montant des heures supplémentaires avec coefficient 1.75
  };

  // Obtenir les demandes de l'utilisateur connecté pour le mois courant
  const userRequests = filterRequestsForCurrentUser();

  return (
    <Container className="container mt-4">
      <div className="reponsehs-container">
        <h3 className="reponsehs-title">
          Mes Demandes d'Heures Supplémentaires -{' '}
          {new Date().toLocaleString('fr-FR', { month: 'long', year: 'numeric' })}
        </h3>

        {/* Affichage de l'état de chargement */}
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Chargement...</span>
            </Spinner>
          </div>
        ) : userRequests.length > 0 ? (
          <Table striped bordered hover className="reponsehs-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Heure Début</th>
                <th>Heure Fin</th>
                <th>Statut</th>
                <th>Montant (DT)</th>
              </tr>
            </thead>
            <tbody>
              {userRequests.map((request) => (
                <tr key={request._id}>
                  <td>{request.date}</td>
                  <td>{request.startHour}</td>
                  <td>{request.endHour}</td>
                  <td>
                    {request.status === 'approved'
                      ? 'Approuvé'
                      : request.status === 'rejected'
                      ? 'Rejeté'
                      : 'En attente'}
                  </td>
                  <td>
                    {request.status === 'approved'
                      ? `${calculateOvertimeAmount(request.startHour, request.endHour).toFixed(2)} DT`
                      : '0'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p className="reponsehs-empty">
            Vous n'avez aucune demande d'heures supplémentaires ce mois-ci.
          </p>
        )}
      </div>
    </Container>
  );
}

export default ReponseHS;
