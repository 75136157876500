import { GETALLSHEETS, GETMYSHEETS } from "../ActionTypes/SheetTypes"

const initialState ={

mySheets : [],
allSheets : []

}

const SheetReducer=(state=initialState,action)=>{

switch (action.type) {
       
       case GETMYSHEETS : return {...state,mySheets : action.payload}
       case GETALLSHEETS : return {...state,allSheets : action.payload}
       case 'UPDATE_SHEET_INFO':
              return {
                  ...state,
                  mySheets: state.mySheets.map((sheet) =>
                      sheet._id === action.payload.sheetId
                          ? {
                                ...sheet,
                                workedHours: action.payload.workedHours,
                                workedMinutes: action.payload.workedMinutes,
                                totalSalaire: action.payload.totalSalaire
                            }
                          : sheet
                  )
              };
       
       default: return state
}

}



export default SheetReducer