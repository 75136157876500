import axios from "axios"
import { GETALLSHEETS, GETMYSHEETS } from "../ActionTypes/SheetTypes"

export const getMySheets= (id)=>async(dispatch)=>{
    try {
       const res = await axios.get(`/api/Sheet/GetSheetsbyOwner/${id}`)
    
       dispatch(
    {
        type : GETMYSHEETS,
        payload : res.data.mySheets
    }
    
       )
    
    } catch (error) {
        console.log(error)
    }
       
    }


    export const updateSheet=(idUser,idsheet,cordSheet)=>async(dispatch)=>{
        try {
            console.log(cordSheet)
             await axios.put(`/api/Sheet/UpdateSheet/${idsheet}`,cordSheet)
            
             dispatch(getMySheets(idUser))

           
            }
        
        catch (error) {
            console.log(error)
        }
    }


    
export const getAllSheets= ()=>async(dispatch)=>{
    try {
       const res = await axios.get(`/api/Sheet/getSheets`)
    
       dispatch(
    {
        type : GETALLSHEETS,
        payload : res.data.mySheets
    }
    
       )
    
    } catch (error) {
        console.log(error)
    }
       
    }

    // SheetActions.js

export const updateSheetInfo = (sheetId, workedHours, workedMinutes, totalSalaire) => async (dispatch) => {
    try {
        // Ici, vous enverrez la mise à jour au backend, si nécessaire.
        dispatch({
            type: 'UPDATE_SHEET_INFO',
            payload: { sheetId, workedHours, workedMinutes, totalSalaire }
        });
    } catch (error) {
        console.error('Error updating sheet info:', error);
    }
};
