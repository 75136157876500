import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { current } from "../Redux/Actions/AuthActions";
import { getMyRequests, getRecivedRequests, updateRequest } from "../Redux/Actions/RequestActions";
import { addNotification } from "../Redux/Actions/NotificationActions";
import Table from "react-bootstrap/Table";
import { Button, DropdownButton, Dropdown, Container, Row, Col } from "react-bootstrap";
import "./NProfil.css";
import ConfButton from './ConfButton';
import ImageDetails from './ImageDetails';
import PdfDetails from "./PdfDetails";
import io from "socket.io-client";
import MyQuizs from "./MyQuizs";


// Connexion au serveur WebSocket (backend)
const socket = io('http://localhost:5000');  // URL de votre serveur WebSocket

const NProfil = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications.notifications);

    useEffect(() => {
        // Enregistrer l'utilisateur lors de la connexion WebSocket
        socket.emit('register', "USER_ID");  // Remplacez par l'ID réel de l'utilisateur
        dispatch(current());
        dispatch(getMyRequests());
        dispatch(getRecivedRequests());

        // Écoutez les notifications du serveur
        socket.on('newNotification', (notification) => {
            dispatch(addNotification(notification));  // Ajoute la notification au Redux
        });

        // Nettoyage lors du démontage du composant
        return () => {
            socket.off('newNotification');
        };
    }, [dispatch]);

    const user = useSelector((state) => state.AuthReducer.user);
    const myRequests = useSelector((state) => state.ReducersRequest.myRequests);
    const myRecivedRequest = useSelector((state) => state.ReducersRequest.myRecivedRequest);

    const [filterRequest, setFilterRequest] = useState("All");
    const [filterCategory, setFilterCategory] = useState("All");
    const [updatedRequests, setUpdatedRequests] = useState(myRecivedRequest);

    useEffect(() => {
        setUpdatedRequests(myRecivedRequest);
    }, [myRecivedRequest]);

    const handleUpdate = (el, status, explanation) => {
        dispatch(updateRequest(el._id, {
            validation: el.validation.map((element) =>
                element.user === user._id ? { ...element, status, explanation } : element
            )
        }));
    };

    const handleFind = (el, id) => el.validation.find((item) => item.user === id);

    const filterByCategory = (requests) => {
        if (filterCategory === "For You Today") {
            return requests.filter(request => request.selectedCategory === "For you Today");
        } else if (filterCategory === "Repetitive Tasks") {
            return requests.filter(request => request.selectedCategory === "Repetitive Tasks");
        } else if (filterCategory === "New Project") {
            return requests.filter(request => request.selectedCategory === "New Project");
        } else {
            return requests;
        } 
    };

    const tableMyRequest = (requests) =>
        filterByCategory(requests).map((el) => (
            <tr key={el._id}>
                <td>{user._id === el.owner._id ? "Me" : el.owner.name}</td>
                <td>{el.objet}</td>
                <td>{el.descriptif}</td>
                <td>
                    <ul>
                        {el.destinataire.map((elo) => (
                            <li key={elo._id}>
                                {handleFind(el, elo._id)?.status === "Confirmed"
                                    ? `Confirmed by ${elo.name}`
                                    : handleFind(el, elo._id)?.status === "Rejected"
                                    ? `Rejected by ${elo.name}`
                                    : `In progress by ${elo.name}`}
                            </li>
                        ))}
                    </ul>
                </td>
                <td>{el.localisation}</td>
                <td>{el.service}</td>
                <td>{el.createdAt && formatDateTime(el.createdAt)}</td>
                 
               
                <td>
                    {el.image.length !== 0 && <ImageDetails el={el} />}  
                    {el.file.length !== 0 && <PdfDetails el={el} />}  
                </td>
                <td
                    className={
                        el.validation.some((item) => item.status === "Rejected")
                            ? "ahmer"
                            : el.validation.every((item) => item.status === "Confirmed")
                            ? "akhdher"
                            : "encours"
                    }
                >
                    {el.validation.some((item) => item.status === "Rejected")
                        ? "Rejected"
                        : el.validation.every((item) => item.status === "Confirmed")
                        ? "Valid"
                        : "Not Valid"}
                </td>
                <td>
                    <ConfButton
                        el={el}
                        user={user}
                        handleUpdate={handleUpdate}
                        handleFind={handleFind}
                    />
                </td>
            </tr>
        ));

        function formatDateTime(timestamp) {
            const date = new Date(Number(timestamp)); // Convert the timestamp to a Date object
            
            // Extract date components
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const year = date.getFullYear();
          
            // Extract time components
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
          
            // Combine date and time
            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
          }

    return (

         <Container className="liste-rapports-twin">
      <h2>Work Flow des demandes </h2>
        <Container className="container mt-4">
            
            <Row className="mb-4 justify-content-center">
      {["All", "For You Today", "Repetitive Tasks", "New Project"].map((category) => (
        <Col key={category} className="d-flex justify-content-center">
          <Button
            className={`category-button ${filterCategory === category ? "active" : ""}`}
            onClick={() => setFilterCategory(category)}
          >
            {category}
          </Button>
        </Col>
      ))}
    </Row>
            <h4>Demandes envoyées</h4>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Sender</th>
                        <th>Objet</th>
                        <th>Description</th>
                        <th>Destinataire</th>
                        <th>Localisation</th>
                        <th>Services</th>
                        <th>Date et Heure</th>
                        <th>Piéces Jointes</th>
                        <th>Statuts</th>
                        <th>Confirmation</th>
                    </tr>
                </thead>
                <tbody>
                    {tableMyRequest(myRequests)}
                </tbody>
            </Table>

            <h4>Demandes reçues</h4>
            <DropdownButton
                title="Statuts"
                variant="dark"
                className="mb-3"
                style={{ maxWidth: '120%' }}
            >
                <Dropdown.Item onClick={() => setFilterRequest("All")}>All</Dropdown.Item>
                <Dropdown.Item onClick={() => setFilterRequest("Confirmed")}>Confirmed</Dropdown.Item>
                <Dropdown.Item onClick={() => setFilterRequest("In progress")}>Pending</Dropdown.Item>
                <Dropdown.Item onClick={() => setFilterRequest("Rejected")}>Rejected</Dropdown.Item>
            </DropdownButton>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Sender</th>
                        <th>Objet</th>
                        <th>Description</th>
                        <th>Destinataire</th>
                        <th>Localisation</th>
                        <th>Services</th>
                        <th>Date et Heure</th>
                        <th>Piéces Jointes</th>
                        <th>Status</th>
                        <th>Confirmation</th>
                    </tr>
                </thead>
                <tbody>
                    {tableMyRequest(
                        filterRequest === "All"
                            ? updatedRequests
                            : updatedRequests.filter(
                                (el) => handleFind(el, user._id)?.status === filterRequest
                            )
                    )}
                </tbody>
            </Table>

        
        </Container>

       
        </Container>
    );
};

export default NProfil;
