import React from 'react'

const CardCollectTwin = ({el}) => {
    return (
      <div >
      <p>Localisation: {el.localisation}</p>
      <p>Date Collect: {el.DateCollect}</p>
      <p>Amount: {el.TotalAmount}</p>
      <p>Feedback: {el.feedback}</p>
    </div>
    )
  }
  
  export default CardCollectTwin