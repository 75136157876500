import { GETMYRECIVEDREQUEST, GETMYREQUESTS, GETREQUESTS } from "../ActionTypes/RequestTypes"
import axios from "axios"



export const getrequests= ()=>async(dispatch)=>{
try {
   const res = await axios.get('/api/request/getRequests')

   dispatch(
{

    type : GETREQUESTS,
    payload : res.data.requests
}

   )

} catch (error) {
    console.log(error)
}


}

export const addRequest = (newRequest, navigate) => async (dispatch) => {
    try {
      console.log("action acttion",newRequest)
      const requestToAdd = new FormData();
      requestToAdd.append('objet', newRequest.objet);
      requestToAdd.append('descriptif', newRequest.descriptif);
      requestToAdd.append('destinataire', JSON.stringify(newRequest.destinataire)); // Convertir en JSON si nécessaire
      requestToAdd.append('owner', newRequest.owner);
      requestToAdd.append('localisation', newRequest.localisation);
      requestToAdd.append('service', newRequest.service);
      requestToAdd.append('validation', JSON.stringify(newRequest.validation));
      requestToAdd.append('selectedCategory', newRequest.selectedCategory);
  
    //   Ajouter plusieurs fichiers
      if (newRequest.file && newRequest.file.length > 0) {
        for (let i = 0; i < newRequest.file.length; i++) {
          await requestToAdd.append('file', newRequest.file[i]); // Notez l'utilisation de 'files' pour un tableau
        }
      }
  
      // Ajouter plusieurs images
      if (newRequest.image && newRequest.image.length > 0) {


        for (let i = 0; i < newRequest.image.length; i++) {
          console.log('Image action ',newRequest.image[i])  
       console.log("newrequest",   newRequest.image)
                 await requestToAdd.append('image', newRequest.image[i]); // Notez l'utilisation de 'images' pour un tableau
        }


        
      }
  console.log('Form data image',requestToAdd.image)

      await axios.post('/api/request/AddRequests', requestToAdd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      dispatch(getMyRequests());
      dispatch(getRecivedRequests());
  
      navigate('/Profil');
    } catch (error) {
      console.log(error);
    }
  };

export const getMyRequests= ()=>async(dispatch)=>{
    try {
       const res = await axios.get('/api/request/getMyRequests',{
        headers : {
            Authorised : localStorage.getItem('token')
        }
    })
    
       dispatch(
    {
    
        type : GETMYREQUESTS,
        payload : res.data.requests
    }
    
       )
    
    } catch (error) {
        console.log(error)
    }
       
    }

    export const getRecivedRequests= ()=>async(dispatch)=>{
        try {
           const res = await axios.get('/api/request/getMyRecivedRequest',{
            headers : {
                Authorised : localStorage.getItem('token')
            }
        })
        
           dispatch(
        {
        
            type : GETMYRECIVEDREQUEST,
            payload : res.data.requests
        }
        
           )
        
        } catch (error) {
            console.log(error)
        }
        
        
        }


            
export const updateRequest=(id,upRequest)=>async(dispatch)=>{
    try {
        console.log('New Requ', upRequest )
        await axios.put(`/api/request/updateRequest/${id}`,upRequest)

        dispatch(getRecivedRequests())
    } catch (error) {
        console.log(error.response.data)
    }
}