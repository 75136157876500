import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './Rapports.css'; 
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {AddRapportsSpintoss} from '../Redux/Actions/RapportSpintossAction';
import { current } from "../Redux/Actions/AuthActions";

const RapportsSpintoss = () => {
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(current())
  },[])

  const user = useSelector((state) => state.AuthReducer.user);
  
  const [emplacement, setEmplacement] = useState('');
  const [date] = useState(new Date().toISOString().split('T')[0]);
  const [espece, setEspece] = useState(0);
  const [reduction10, setReduction10] = useState(0);
  const [reduction20, setReduction20] = useState(0);
  const [reductionTwin, setReductionTwin] = useState(0);
  const [privilege, setPrivilege] = useState(0);
  const [glovo, setGlovo] = useState(0);
  const [tresto, setTresto] = useState(0);
  const [tpe, setTpe] = useState(0);
  const [cheque, setCheque] = useState(0);
   const [packAnnif, setPackannif] = useState(0);
  const [amount, setAmount] = useState(0);
  const [expense, setExpense] = useState(0);
  const [explanation, setExplanation] = useState('');


  const navigate = useNavigate();

  const calculateTotalAmount = () => {
    return parseFloat(
      (
        parseFloat(espece) +
        parseFloat(reduction10) +
        parseFloat(reduction20) +
        parseFloat(reductionTwin) +
        parseFloat(privilege) +
        parseFloat(glovo) +
        parseFloat(tresto) +
        parseFloat(tpe) +
        parseFloat(cheque) +
        parseFloat(packAnnif)
      ).toFixed(3)
    );
  };
  

  const handleNumberInput = (e, setState) => {
    const value = e.target.value;
    if (/^\d*\.?\d{0,3}$/.test(value)) {
      setState(parseFloat(value || 0).toFixed(3))
    }
  };

  const handleRapportSpintoss = (e) => {
    e.preventDefault();

    // Changement ici : permettre les zéros et enlever la vérification pour les montants négatifs
    if (!emplacement || !date || !explanation) {
      alert("Veuillez remplir tous les champs correctement.");
      return;
    }

    const rapportData = {
       DateCollect : date.slice(0,10),
      localisation : emplacement,
      Espece: parseFloat(espece),
      Reduction10: parseFloat(reduction10),
      Reduction20: parseFloat(reduction20),
      ReductionTwin: parseFloat(reductionTwin),
      Privilege: parseFloat(privilege),
      Glovo: parseFloat(glovo),
      Tresto: parseFloat(tresto),
      Tpe: parseFloat(tpe),
      Cheque: parseFloat(cheque),
      PackAnnif: parseFloat(packAnnif),
      TotalAmount: parseFloat(calculateTotalAmount()), 
      DailyExpenses: parseFloat(expense),
      feedback : explanation,
      owner : user._id
    };
    

    dispatch(AddRapportsSpintoss(rapportData,navigate))
  };

  return (
    <div className="list-rapports">   
      <h3>Rapport Spintoss</h3>

    <Form className="rapport-form" onSubmit={handleRapportSpintoss}>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridState" className="rapport-form-group">
          <Form.Label>Emplacement</Form.Label>
          <Form.Select 
            onChange={(e) => setEmplacement(e.target.value)} 
            className="rapport-select" 
            defaultValue="Choisissez un emplacement...">
            <option disabled>Choisissez un emplacement...</option>
            <option>Spintoss- Manar City</option>
            <option>Spintoss - Tunisia Mall</option>
            <option>Spintoss - Jardins d'eL Menzah</option>
            <option>Spintoss - Mall of Djerba</option>
          </Form.Select>
        </Form.Group>
          
        <Form.Group as={Col} controlId="formGridDate" className="rapport-form-group">
      <Form.Label>Date du jour</Form.Label>
      <Form.Control 
        type="date" 
        className="rapport-input" 
        value={date} 
        readOnly // Rend le champ non modifiable
      />
    </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="formEspece" className="rapport-form-group">
          <Form.Label>Espéces</Form.Label>
          <Form.Control 
            type="text" 
            value={espece}
            onChange={(e) =>  setEspece(e.target.value)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formReduction10" className="rapport-form-group">
          <Form.Label>Reduction 10%</Form.Label>
          <Form.Control 
            type="text" 
            value={reduction10}
            onChange={(e) => handleNumberInput(e, setReduction10)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formReduction20" className="rapport-form-group">
          <Form.Label>Réduction 20%</Form.Label>
          <Form.Control 
            type="text" 
            value={reduction20}
            onChange={(e) => handleNumberInput(e,setReduction20)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="formReductionTwin" className="rapport-form-group">
          <Form.Label>Réduction TwinClub</Form.Label>
          <Form.Control 
            type="text" 
            value={reductionTwin}
            onChange={(e) => handleNumberInput(e,setReductionTwin)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

       

        <Form.Group as={Col} controlId="formGlovo" className="rapport-form-group">
          <Form.Label>Glovo</Form.Label>
          <Form.Control 
            type="text" 
            value={glovo}
            onChange={(e) => handleNumberInput(e,setGlovo)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
      </Row>

      <Row>
        
        <Form.Group as={Col} controlId="formTresto" className="rapport-form-group">
          <Form.Label>Ticket Resto</Form.Label>
          <Form.Control 
            type="text" 
            value={tresto}
            onChange={(e) => handleNumberInput(e, setTresto)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
      
        <Form.Group as={Col} controlId="formTpe" className="rapport-form-group">
          <Form.Label>TPE</Form.Label>
          <Form.Control 
            type="text" 
            value={tpe}
            onChange={(e) => handleNumberInput(e, setTpe)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formCheque" className="rapport-form-group">
          <Form.Label>Chèque</Form.Label>
          <Form.Control 
            type="text" 
            value={cheque}
            onChange={(e) => handleNumberInput(e, setCheque)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
      </Row>

      <Row>
       
      <Form.Group as={Col} controlId="formPrivilege" className="rapport-form-group">
          <Form.Label>Privilége</Form.Label>
          <Form.Control 
            type="text" 
            value={privilege}
            onChange={(e) => handleNumberInput(e, setPrivilege)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formPackAnnif" className="rapport-form-group">
          <Form.Label>Pack Anniversaire</Form.Label>
          <Form.Control 
            type="text" 
            value={packAnnif}
            onChange={(e) => handleNumberInput(e, setPackannif)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
      </Row>

     

      <Row>
        <Form.Group as={Col} controlId="formGridAmount" className="rapport-form-group">
          <Form.Label>Montant Total</Form.Label>
          <Form.Control 
            value={calculateTotalAmount()}
            readOnly
            type="text" 
            className="rapport-input" 
          />
        </Form.Group>
      
        <Form.Group as={Col} controlId="formGridExpense" className="rapport-form-group">
          <Form.Label>Dépenses</Form.Label>
          <Form.Control 
            type="text" 
            onChange={(e) => handleNumberInput(e, setExpense)}
            className="rapport-input" 
            placeholder="0" 
          />
        </Form.Group>
        </Row>

        <Form.Group as={Col} controlId="formExplanation" className="rapport-form-group">
          <Form.Label>Explication</Form.Label>
          <Form.Control 
            as="textarea" 
            rows={2} 
            onChange={(e) => setExplanation(e.target.value)} 
            placeholder="Entrez une explication"
          />
        </Form.Group>
      

      <Button onClick={(e) => handleRapportSpintoss(e)} variant="primary" type="submit" className="rapport-button">
        Soumettre
      </Button>
    </Form>
    </div>
  );
};

export default RapportsSpintoss;
