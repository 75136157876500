import React, { useEffect } from 'react'
// import Button from 'react-bootstrap/Button';
import{useDispatch, useSelector} from 'react-redux'
import { addRequest, getrequests } from '../Redux/Actions/RequestActions';
import CardRequests from './CardRequests';

const ListRequests = () =>{
  const dispatch = useDispatch()

useEffect(()=>{

  dispatch(getrequests())
},[])

const requests = useSelector(state => state.ReducersRequest.requests)

  return (
    <div> 
      {
        requests.map((el,i,t)=> <CardRequests key ={el._id} el={el}/>)
      }

    </div>

  )
}

export default ListRequests
