// NotificationBadge.js
import React from 'react';
import './NotificationBadge.css';

const NotificationBadge = ({ count }) => (
  <div className="notification-badge">
    {count > 0 && <span className="badge-count">{count}</span>}
    <span className="initial">F</span> {/* Replace 'F' with the user's initial dynamically */}
  </div>
);

export default NotificationBadge;
