

import{GETRAPPORTS} from "../ActionTypes/RapportTypes"

const initialState = {
    rapports : []

}
const RapportReducer=(state=initialState, action) =>{
switch (action.type) {
    case GETRAPPORTS  : return {...state, rapports: action.payload}


    default: return state
}
}

export default RapportReducer