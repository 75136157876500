import{GETRAPPORTS} from "../ActionTypes/RapportTypes"
import axios from "axios"

 export const getrapportwell= ()=>async(dispatch)=>{
    try {
       const res = await axios.get('/api/Rapports/GetRapports')
    
       dispatch(
    {
        type : GETRAPPORTS,
        payload : res.data.rapports
    }
    
       )
    
    } catch (error) {
        console.log(error)
    }
       
    }
    

    
export const addRapport=(newRapport,navigate)=>async(dispatch)=>{
    
   try {
      
       await axios.post('/api/Rapports/AddRapports',newRapport,{
        headers : {
            Authorised : localStorage.getItem('token')
        }
    })

       dispatch(getrapportwell())

        navigate('/Accueil')
      
   } catch (error) {
       console.log(error)
   }
}