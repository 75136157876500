import React, { useEffect } from 'react';
import { getOneQuiz, getQuizGroupedByDestinataire } from '../Redux/Actions/QuizActions';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './QuizGroupedResponse.css'; // Import du CSS

const QuizGroupedResponse = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOneQuiz(id));
    dispatch(getQuizGroupedByDestinataire(id));
  }, []);
  function formatDateTime(timestamp) {
    const date = new Date(Number(timestamp)); // Convert the timestamp to a Date object
    
    // Extract date components
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
  
    // Extract time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // Combine date and time
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  
  const oneQuiz = useSelector((state) => state.QuizReducer.oneQuiz);
  const quizGrouped = useSelector((state) => state.QuizReducer.quizGrouped);

  return (
    <div className="quiz-grouped-response-container">
      {oneQuiz && (
        <div>
          <h1 className="quiz-title">{oneQuiz.title}</h1>
          <h2 className="quiz-location">{oneQuiz.localisation}</h2>
        </div>
      )}

      {quizGrouped.map((el, i) => (
        <div key={i} className="quiz-grouped-item">
          <h3 className="destinataire-name">Réponse de : {el?.destinataire?.name}</h3>

          {el.reponses.length === 0 ? (
            <p className="no-response">Pas de réponse pour le moment</p>
          ) : (
            <table className="response-table">
              <thead>
                <tr>
                  <th>Objet</th>
                  <th>Statut</th>
                  <th>Explication</th>
                </tr>
              </thead>
              <tbody>
                {el.reponses.map((res, i) => (
                  <tr key={i}>
                    <td>{res.question.objet}</td>
                    <td className={`response-status ${res.status ? 'conforme' : 'non-conforme'}`}>
                      {res.status ? 'Conforme' : 'Non Conforme'}
                    </td>
                   
                    <td className="response-explanation">{res.createdAt && formatDateTime(res.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ))}
    </div>
  );
};

export default QuizGroupedResponse;
