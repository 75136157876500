import{GETSPINTOSSRAPPORT} from "../ActionTypes/RapportSpintoss"
import { ADD_RAPPORT_SPINTOSS_SUCCESS, ADD_RAPPORT_SPINTOSS_FAILURE } from "../ActionTypes/RapportSpintoss"

import axios from "axios"



 export const getrapportspintoss= ()=>async(dispatch)=>{
    try {
       const res = await axios.get('/api/RapportsSpintoss/GetRapportsSpintoss')
    
       dispatch(
    {
        type :GETSPINTOSSRAPPORT,
        payload : res.data.RapportsSpintoss
    }
    
       )
    
    } catch (error) {
        console.log(error)
    }
    
        }

        export const AddRapportsSpintoss=(newRapportspintoss,navigate)=>async(dispatch)=>{
    
         try {
            
             await axios.post('/api/RapportsSpintoss/AddRapportsSpintoss',newRapportspintoss,{
              headers : {
                  Authorised : localStorage.getItem('token')
              }
          })
      
             dispatch(getrapportspintoss())
      
              navigate('/Accueil')
           
         } catch (error) {
             console.log(error)
         }
      }

      