import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { updateSheet } from '../Redux/Actions/SheetActions';
import { useDispatch } from 'react-redux';

function ValidSortie({userId,sheet,getNow}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch()

  function calculerHeuresTravailleesEtSalaire(debut, fin, entree, sortie, coutHoraire) {
    // Convertir les heures en objets Date
    const [heureDebut, minuteDebut] = debut.split(":").map(Number);
    const [heureFin, minuteFin] = fin.split(":").map(Number);
    const [heureEntree, minuteEntree] = entree.split(":").map(Number);
    const [heureSortie, minuteSortie] = sortie.split(":").map(Number);
  
    const debutTravail = new Date();
    debutTravail.setHours(heureDebut, minuteDebut, 0, 0);
  
    const finTravail = new Date();
    finTravail.setHours(heureFin, minuteFin, 0, 0);
  
    const heureEntreeObj = new Date();
    heureEntreeObj.setHours(heureEntree, minuteEntree, 0, 0);
  
    const heureSortieObj = new Date();
    heureSortieObj.setHours(heureSortie, minuteSortie, 0, 0);
  
    // Ajuster l'entrée et la sortie dans la plage de travail
    const entreeEffective = heureEntreeObj < debutTravail ? debutTravail : heureEntreeObj;
    const sortieEffective = heureSortieObj > finTravail ? finTravail : heureSortieObj;
  
    // Calculer le temps travaillé sans pénalités
    let minutesTravaillees = 0;
  
    // Calculer le temps travaillé si sortie > entrée
    if (sortieEffective > entreeEffective) {
      const differenceMs = sortieEffective - entreeEffective;
      minutesTravaillees = Math.floor(differenceMs / (1000 * 60)); // Différence en minutes
    }
  
    // Calculer les pénalités de retard et de sortie anticipée
    let penaliteEntree = 0;
    let penaliteSortie = 0;
  
    // Pénalité pour retard à l'entrée
    let retardMinutes = 0;
    if (heureEntreeObj > debutTravail) {
      const retardMs = heureEntreeObj - debutTravail;
      retardMinutes = Math.floor(retardMs / (1000 * 60)); // Retard en minutes
      if (retardMinutes > 60) {
        // Retard supérieur à 1 heure : 4 heures de pénalité
        penaliteEntree = 4 * coutHoraire;
      } else if (retardMinutes > 15) {
        // Retard supérieur à 15 minutes mais ≤ 1 heure : 1 heure de pénalité
        penaliteEntree = 1 * coutHoraire;
      }
    }
  
    // Pénalité pour sortie anticipée
    let sortieAnticipeeMinutes = 0;
    if (heureSortieObj < finTravail) {
      const sortieAnticipeeMs = finTravail - heureSortieObj;
      sortieAnticipeeMinutes = Math.floor(sortieAnticipeeMs / (1000 * 60)); // Anticipation en minutes
      if (sortieAnticipeeMinutes > 60) {
        // Sortie anticipée de plus d'une heure : 4 heures de pénalité
        penaliteSortie = 4 * coutHoraire;
      } else if (sortieAnticipeeMinutes >= 15) {
        // Sortie anticipée entre 15 et 60 minutes : 1 heure de pénalité
        penaliteSortie = 1 * coutHoraire;
      }
    }
  
    // Calculer le salaire journalier sans pénalité
    const salaireJournalier = parseFloat(((minutesTravaillees / 60) * coutHoraire).toFixed(2)); // Salaire sans pénalité
  
    // Calculer le total des pénalités (retard + sortie anticipée)
    let penaliteTotale = penaliteEntree + penaliteSortie;
  
    // Limiter la pénalité totale à 4 heures de travail par jour
    const maxPenalite = 4 * coutHoraire; // 4 heures maximum
    if (penaliteTotale > maxPenalite) {
      penaliteTotale = maxPenalite;
    }
  
    // Calculer le salaire net après pénalités
    let salaireNet = salaireJournalier - penaliteTotale; // Salaire net après déductions des pénalités
  
    // S'assurer que le salaire net ne soit pas négatif
    if (salaireNet < 0) {
      salaireNet = 0;
    }
  
    // Retourner les résultats
    return {
      heuresTravaillees: minutesTravaillees, // Total des minutes travaillées
      salaireJournalier, // Salaire journalier sans pénalité
      salaireNet: parseFloat(salaireNet.toFixed(2)), // Salaire net après déduction des pénalités
      penaliteEntree: parseFloat(penaliteEntree.toFixed(2)), // Pénalité d'entrée (retard)
      penaliteSortie: parseFloat(penaliteSortie.toFixed(2)), // Pénalité de sortie (anticipation)
      penaliteTotale: parseFloat(penaliteTotale.toFixed(2)) // Pénalité totale (retard + sortie anticipée)
    };
  }
  
  


  const handleValid=()=>{
    var nowDate = getNow().formattedTime
    var debut = sheet.debut
    var fin = sheet.fin
    var entree = sheet.entree
    var sortie = nowDate
    var coutHoraire = sheet.salaireMensuel
    var {heuresTravaillees,salaireJournalier,salaireNet,penaliteEntree,penaliteSortie,penaliteTotale } = sheet && calculerHeuresTravailleesEtSalaire(debut,fin,entree,sortie,coutHoraire)
    dispatch(updateSheet(userId,sheet._id,{end : true,start : false,sortie : nowDate,salaireNet,penaliteEntree,penaliteSortie,penaliteTotale, salaireJournalier, nbrHeuresJournalier : heuresTravaillees}));handleClose()
  }
  

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        End
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>End</Modal.Title>
        </Modal.Header>
        <Modal.Body>Validate ? </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button onClick={handleValid}>Validate</Button>
        
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ValidSortie;