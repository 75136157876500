import { 
  ADD_OVERTIME_REQUEST, 
  GET_MY_OVERTIME_REQUESTS, 
  GET_OVERTIME_REQUESTS, 
  APPROVE_OVERTIME_REQUEST, 
  REJECT_OVERTIME_REQUEST, 
  SET_COEFFICIENT
} from "../ActionTypes/OvertimeActionTypes";

const initialState = {
  myOvertimeRequests: [], // Demandes spécifiques à l'utilisateur connecté
  overtimeRequests: [], // Toutes les demandes d'heures supplémentaires (admin ou autres cas)
  loading: false, // Pour indiquer si une action est en cours
  error: null, // Pour gérer les erreurs éventuelles
  coefficients: {}, 
};

const OvertimeRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true, error: null };

    case GET_OVERTIME_REQUESTS:
      return { ...state, overtimeRequests: action.payload, loading: false };

    case ADD_OVERTIME_REQUEST:
      console.log('Nouvelle demande ajoutée :', action.payload); // Ajout du log pour vérification
      return {
        ...state,
        overtimeRequests: [...state.overtimeRequests, action.payload],
      };

    case APPROVE_OVERTIME_REQUEST:
      return {
        ...state,
        overtimeRequests: state.overtimeRequests.map((request) =>
          request._id === action.payload
            ? { ...request, status: 'approved' }
            : request
        ),
      };

    case REJECT_OVERTIME_REQUEST:
      return {
        ...state,
        overtimeRequests: state.overtimeRequests.map((request) =>
          request._id === action.payload
            ? { ...request, status: 'rejected' }
            : request
        ),
      };

    case GET_MY_OVERTIME_REQUESTS:
      console.log('Données des demandes utilisateur :', action.payload); // Ajout du log pour vérification
      return {
        ...state,
        myOvertimeRequests: action.payload,
        loading: false,
      };

    case 'ERROR':
      return { ...state, loading: false, error: action.payload };

    case SET_COEFFICIENT:
      return {
        ...state,
        coefficients: {
          ...state.coefficients,
          [action.payload.requestId]: action.payload.value,
        },
      };

    default:
      return state;
  }
};

export default OvertimeRequestReducer;
