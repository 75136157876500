import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { current, getAllusers } from '../Redux/Actions/AuthActions';
import { Button } from 'react-bootstrap';
import { addQuiz, getAllQuiz } from '../Redux/Actions/QuizActions';
import { Link } from 'react-router-dom';
import './AddQuiz.css'; // Import du CSS

const AddQuiz = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(current());
    dispatch(getAllusers());
    dispatch(getAllQuiz());
  }, []);

  const user = useSelector(state => state.AuthReducer.user);
  const Users = useSelector(state => state.AuthReducer.Users);
  const Quizs = useSelector(state => state.QuizReducer.Quizs);

  const [title, setTitle] = useState('');
  const [destinataires, setDestinataires] = useState([]);
  const [localisation, setLocalisation] = useState('');

  const handleAddQuiz = e => {
    e.preventDefault();
    dispatch(addQuiz({ title, destinataires: destinataires.map(el => el.id), localisation }));
  };

  return (
    <div className="page-container">
      <h1 className="page-title">Gestion des Fiches de Suivi</h1>

      {/* Section formulaire */}
      <div className="form-container">
        <h3>Créer Une fiche </h3>
        <Form>
          <Form.Group className="form-group">
            <Form.Label>Title</Form.Label>
            <Form.Control onChange={(e) => setTitle(e.target.value)} />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Localisation</Form.Label>
            <Form.Select onChange={(e) => setLocalisation(e.target.value)}>
              <option>Localisation</option>
              <option value="TP-Manar City">TP-Manar City</option>
              <option value="TP-Tunisia Mall">TP-Tunisia Mall</option>
              <option value="TP-Jardins d'El Menzah">TP-Jardins d'El Menzah</option>
              <option value="JZ-Djerba Mall">JZ-Djerba Mall</option>
              <option value="Spintoss-Les Jardins">Dépôt</option>
              <option value="Administration">Administration</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Destinataire</Form.Label>
            {destinataires.map((el, i) => (
              <div key={i} className="destinataire-item">
                <h5>{el.name}</h5>
                <Button
                  variant="danger"
                  onClick={() => setDestinataires(destinataires.filter((del) => del.id !== el.id))}
                >
                  Supprimer
                </Button>
              </div>
            ))}
            <Form.Select
              onChange={(e) => setDestinataires([...destinataires, { id: e.target.value, name: Users.find(el => el._id === e.target.value).name }])}
            >
              <option>Choisir un destinataire</option>
              {Users.filter(el => el._id !== user._id).map((el, i) => (
                <option key={i} value={el._id}>
                  {el.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Button className="add-button" onClick={(e) => handleAddQuiz(e)}>
            Ajouter une fiche
          </Button>
        </Form>
      </div>

      {/* Section liste des quiz */}
      <div className="quiz-container">
        <h2 className="quiz-title">Liste de Suivi</h2>
        <div className="quiz-list">
          {Quizs.map((el, i) => (
            <div className="quiz-item" key={i}>
              <h2>{el.title}</h2>
              <h3>{el.localisation}</h3>
              <ul>
                {el.destinataires.map((destt, j) => (
                  <li key={j}>{destt.name}</li>
                ))}
              </ul>
              <div className="action-links">
                <Link className="action-link" to={`/QuizDescription/${el._id}`}>
                  Ajouter Questions
                </Link>
                <Link className="action-link" to={`/QuizGroupedResponse/${el._id}`}>
                  Voir Résultats
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddQuiz;
