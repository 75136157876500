import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addQuestion, getAllQuizQuestions, getOneQuiz } from '../Redux/Actions/QuizActions';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import './QuizDescription.css'; // Import du CSS

const QuizDescription = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOneQuiz(id));
    dispatch(getAllQuizQuestions(id));
  }, []);

  const [objet, setObjet] = useState('');
  const [departement, setDepartement] = useState('');

  const oneQuiz = useSelector(state => state.QuizReducer.oneQuiz);
  const Questions = useSelector(state => state.QuizReducer.Questions);

  const handleAddQuestion = (e) => {
    e.preventDefault();
    dispatch(addQuestion({ objet, departement, quiz: id }));
  };

  return (
    <div className="page-container">
      {/* Informations du Quiz */}
      {oneQuiz && (
        <div className="quiz-info">
          <h1>{oneQuiz.title}</h1>
          <h2>{oneQuiz.localisation}</h2>
        </div>
      )}

      {/* Formulaire pour ajouter une question */}
      <div className="form-container">
        <h3>Ajouter une Question</h3>
        <Form>
          <Form.Group className="form-group">
            <Form.Label>Question : </Form.Label>
            <Form.Control onChange={(e) => setObjet(e.target.value)} />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label>Département :</Form.Label>
            <Form.Control onChange={(e) => setDepartement(e.target.value)} />
          </Form.Group>

          <Button className="add-button" onClick={(e) => handleAddQuestion(e)}>
            Ajouter une Question
          </Button>
        </Form>
      </div>

      {/* Liste des Questions */}
      <div className="questions-container">
        <h2 className="questions-title">Liste des Questions</h2>
        <ul className="questions-list">
          {Questions &&
            Questions.map((el, i) => (
              <li key={i}>
                <span>{el.objet}</span> <span>  {el.departement}</span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default QuizDescription;
