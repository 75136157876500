import axios from "axios"
import { GETALLQUIZQUESTIONS, GETALLQUIZS, GETMYQUIZS, GETONEQUIZ, GETQUIZGROUPED } from "../ActionTypes/QuizTypes"

export const getAllQuiz= ()=>async(dispatch)=>{
    try {
       const res = await axios.get('/api/Quiz/GetAllQuiz')
        console.log(res.data.Quizs)
       dispatch(
    {
        type : GETALLQUIZS,
        payload : res.data.Quizs
    }
    
       )
    
    } catch (error) {
        console.log(error)
    }
       
    }

    
export const getAllQuizQuestions= (id)=>async(dispatch)=>{
    try {
       const res = await axios.get(`/api/Question/GetAllQuizQuestion/${id}`)
        console.log(res.data.Quizs)
       dispatch(
    {
        type : GETALLQUIZQUESTIONS,
        payload : res.data.Questions
    }
    
       )
    
    } catch (error) {
        console.log(error)
    }
       
    }



    export const addQuiz=(newQuiz)=>async(dispatch)=>{
    
        try {
                       console.log(newQuiz)
            await axios.post('/api/Quiz/AddQuiz',newQuiz)
     
            dispatch(getAllQuiz())
     
           
        } catch (error) {
            console.log(error)
        }
     }


     export const getOneQuiz= (id)=>async(dispatch)=>{
        try {
           const res = await axios.get(`/api/Quiz/GetOneRequest/${id}`)
            console.log(res.data.oneQuiz)
           dispatch(
        {
            type : GETONEQUIZ,
            payload : res.data.oneQuiz
        }
        
           )
        
        } catch (error) {
            console.log(error)
        }
           
        }
    
        export const addQuestion=(newQuestion)=>async(dispatch)=>{
    
            try {
                           console.log(newQuestion)
                await axios.post('/api/Question/AddQuestion',newQuestion)
         
                dispatch(getAllQuizQuestions(newQuestion.quiz))
         
               
            } catch (error) {
                console.log(error)
            }
         }


         export const getMyQuizs= ()=>async(dispatch)=>{
            try {
               const res = await axios.get(`/api/Quiz/getQuizDestinataires`,{
                headers : {
                    Authorised : localStorage.getItem('token')
                }
            })
                console.log(res.data.Quizs)
               dispatch(
            {
                type : GETMYQUIZS,
                payload : res.data.quizzes
            }
            
               )
            
            } catch (error) {
                console.log(error)
            }
               
            }

            export const addResponses=(userId,quizId,reponses,navigate)=>async(dispatch)=>{
    
                try {
                    for (let i = 0; i < reponses.length; i++) {
                        

                        await axios.post('/api/Reponse/AddReponse',{
                            status : reponses[i].status,
                            explanation : reponses[i].explanation,
                            user : userId,
                            quiz : quizId,
                            question : reponses[i].question
                        })
                        
                    }
                    
                    navigate('/Profil')
             
                   
                } catch (error) {
                    console.log(error)
                }
             }
    


             export const getQuizGroupedByDestinataire= (id)=>async(dispatch)=>{
                try {
                   const res = await axios.get(`/api/Quiz/quiz/${id}/reponses`)
                    console.log(res.data.data)
                   dispatch(
                {
                    type : GETQUIZGROUPED,
                    payload : res.data.data
                }
                
                   )
                
                } catch (error) {
                    console.log(error)
                }
                   
                }
            