import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getrapporttwin } from "../Redux/Actions/RapportTwinAction";
import CardCollectTwin from "./CardCollectTwin";
// import './ListRapportsTwin.css';
import { Table, Button } from 'react-bootstrap';
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";



function ListCollectTwin() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getrapporttwin());
  }, [dispatch]);

  const rapports = useSelector((state) => state.RapportTwinReducer.RapportsTwin) 

  const calcul=(t)=>{
    var Somme = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.TotalAmount,
      0
    )

    var totalDailyExpenses = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.DailyExpenses,
      0
    )
  

    var CollecteMachinesS = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.CollecteMachines,
      0
    )
    var MachineBoxeS = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.MachineBoxe,
      0
    )
    var MachineFootS = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.MachineFoot,
      0
    )
    var MachineBoxeEnfantS = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.MachineBoxeEnfant,
      0
    )

    var BillardAmericainS = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.BillardAmericain,
      0
    )
    var BabyFootS = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.BabyFoot,
      0
    )
    var BallesGiftS = t.reduce(
      (accumulator, currentValue) => accumulator + currentValue.BallesGift,
      0
    )


  
    var resultatnet = Somme - totalDailyExpenses

    return {Somme,totalDailyExpenses,resultatnet,MachineBoxeEnfantS,CollecteMachinesS,MachineBoxeS,MachineFootS,BillardAmericainS,BabyFootS,BallesGiftS}
  }

  const [somme,setSomme] = useState(0)
  // States for filters
  const [localisation, setLocalisation] = useState("All");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState(rapports);
  const [recherche,setRecherche] = useState(false)

  // Function to apply the filter
  const filterData = () => {
    setRecherche(true)
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    const filtered = rapports.filter((entry) => {
      const entryDate = new Date(entry.DateCollect);

      // Check if location is "All" or matches the entry location
      const isLocationMatch = localisation === "All" || entry.localisation === localisation;

      // Check if the date falls within the specified range
      const isDateInRange = (!start || entryDate >= start) && (!end || entryDate <= end);

      return isLocationMatch && isDateInRange;
    });

    setFilteredData(filtered);
    setSomme(calcul(filtered))
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Rapport Résultats", 10, 10);
    doc.autoTable({
      startY: 20,
      head: [['#', 'Localisation', 'TotalAmount', 'Expenses', 'Net']],
      body: filteredData.map((el, i) => [i + 1, el.localisation, el.TotalAmount, el.DailyExpenses, el.TotalAmount - el.DailyExpenses])
    });
    doc.save('rapport_resultats.pdf');
  };

  // Fonction pour télécharger en Excel
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredData.map((el, i) => ({
        "#": i + 1,
        "Localisation": el.localisation,
        "TotalAmount": el.TotalAmount,
        "Expenses": el.DailyExpenses,
        "Net": el.TotalAmount - el.DailyExpenses,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Rapports");
    XLSX.writeFile(workbook, 'rapport_resultats.xlsx');
  };


  return (
    <div className="liste-rapports-twin">
      <h2>Recherche des collectes et des Partenariats</h2>
      <div className="filter-form">
      
<label>
          Localisation:
          <select value={localisation} onChange={(e) => setLocalisation(e.target.value)}>
            <option value="All">All</option>
            <option>Manar City</option>
            <option>Tunisia Mall</option>
            <option>Jardins d'El Menzah</option>
            <option>Jump Zone</option>
            <option>Palmarium</option>
          </select>
        </label>

        <label>
          Start Date:
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>

        <label>
          End Date:
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>

        <button onClick={filterData}>Confirm</button>
      </div>

  {
    recherche &&

    <div className="filtered-data">
    <h3>Résultats trouvés</h3>
    <div className="results-summary">

        <h3>Localisation : {localisation}</h3>
        <h3> Collecte Machines {somme.CollecteMachinesS}</h3>
        <h3> Machine de Boxe {somme.MachineBoxeS}</h3>
        <h3> Machine de Foot {somme.MachineFootS} </h3>
        <h3> Machine de Boxe Enfant {somme.MachineBoxeEnfantS}</h3>
        <h3> Billard Americain  {somme.BillardAmericainS}</h3>
        <h3> Baby Foot {somme.BabyFootS}</h3>
        <h3> Distributeur de Balles Gift {somme.BallesGiftS}</h3>
        <h3>TotalAmount: {somme.Somme}</h3>
        <h3>Expenses: {somme.totalDailyExpenses}</h3>
        <h3>Net: {somme.resultatnet}</h3>
    </div>

    <Button variant="primary" onClick={downloadPDF} style={{ marginRight: "10px" }}>
      Télécharger en PDF
    </Button>
    <Button variant="success" onClick={downloadExcel}>
      Télécharger en Excel
    </Button>

    {filteredData?.length > 0 ? (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Rapport</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((el, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td><CardCollectTwin el={el} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <p>Aucun résultat trouvé</p>
    )}
  </div>
  }

    </div>

   
  );
}

export default ListCollectTwin;
