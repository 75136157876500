
import{ GETSPINTOSSRAPPORT} from "../ActionTypes/RapportSpintoss"

const initialState = {
    rapportsSpintoss : []

}
const RapportSpintossReducer=(state=initialState, action) =>{
switch (action.type) {
    case GETSPINTOSSRAPPORT : return {...state, RapportsSpintoss : action.payload}

    default: return state
}
}



export default RapportSpintossReducer