import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { register } from '../Redux/Actions/AuthActions';
import Container from 'react-bootstrap/Container';
import './Register.css';
import logo from "../image/flow.png";

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [matricule, setMatricule] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    dispatch(register({ name, email, password, matricule }, navigate));
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 register-background">
      <div className="register-container shadow rounded p-5 text-center">
        <img src={logo} alt="Mon logo" className="mb-4" style={{ width: '180px' }} />
        
        

        <Form onSubmit={handleRegister}>
          <Form.Group className="mb-3">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Votre nom"
              className="input-field"
              value={name}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Matricule</Form.Label>
            <Form.Control
              onChange={(e) => setMatricule(e.target.value)}
              type="text"
              placeholder="Matricule"
              className="input-field"
              value={matricule}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Adresse Email</Form.Label>
            <Form.Control
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Votre email"
              className="input-field"
              value={email}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Mot de passe</Form.Label>
            <Form.Control
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Votre mot de passe"
              className="input-field"
              value={password}
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="w-100 btn-submit">
            Register
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Register;
