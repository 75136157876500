import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllOvertimeRequests,
  approveOvertimeRequest,
  rejectOvertimeRequest,
} from '../Redux/Actions/OvertimeActions';
import './GestionHR.css';
import { Container, Spinner } from 'react-bootstrap';

function GestionHR() {
  const dispatch = useDispatch();
  
  // Sélection des données dans le state Redux
  const { overtimeRequests = [], loading } = useSelector((state) => state.OvertimeRequestReducer); // Récupération des demandes
  const Users = useSelector((state) => state.AuthReducer.Users || []); // Liste des utilisateurs

  // Charger les demandes dès le montage du composant
  useEffect(() => {
    dispatch(getAllOvertimeRequests());
  }, [dispatch]);

  // Rafraîchir la liste des demandes après une mise à jour
  const refreshOvertimeRequests = () => {
    dispatch(getAllOvertimeRequests());
  };

  // Approuver une demande
  const handleApprove = (requestId) => {
    dispatch(approveOvertimeRequest(requestId)).then(() => {
      refreshOvertimeRequests();
    });
  };

  // Rejeter une demande
  const handleReject = (requestId) => {
    dispatch(rejectOvertimeRequest(requestId)).then(() => {
      refreshOvertimeRequests();
    });
  };

  // Trouver le nom de l'employé correspondant à l'ID
  const getEmployeeName = (employeId) => {
    const employee = Users.find((user) => user._id === employeId);
    return employee ? employee.name : 'Non spécifié';
  };

  return (
    <Container className="container mt-4">
      <div className="gestion-hr-container">
        <h3 className="gestion-hr-title">Gestion des Demandes d'Heures Supplémentaires</h3>
        <div className="gestion-hr-section">
          {/* Chargement en cours */}
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Chargement...</span>
              </Spinner>
            </div>
          ) : overtimeRequests.length > 0 ? (
            <table className="gestion-hr-table">
              <thead>
                <tr>
                  <th>Employé</th>
                  <th>Date</th>
                  <th>Heure Début</th>
                  <th>Heure Fin</th>
                  <th>Statut</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {overtimeRequests.map((request) => (
                  <tr key={request._id}>
                    <td>{getEmployeeName(request.employeId)}</td>
                    <td>{request.date}</td>
                    <td>{request.startHour}</td>
                    <td>{request.endHour}</td>
                    <td>
                      {request.status === 'pending' ? 'En attente' : request.status}
                    </td>
                    <td>
                      {request.status === 'pending' && (
                        <div className="gestion-hr-actions">
                          <button
                            onClick={() => handleApprove(request._id)}
                            className="btn btn-success"
                          >
                            Approuver
                          </button>
                          <button
                            onClick={() => handleReject(request._id)}
                            className="btn btn-danger"
                          >
                            Rejeter
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="gestion-hr-empty">Aucune demande à traiter.</p>
          )}
        </div>
      </div>
    </Container>
  );
}

export default GestionHR;
