import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';

const Errors = () => {
   const Erreur = useSelector(state => state.ErrorsReducer)
    return (
        <div>
        { 
            Erreur.map((el,i,t)=>
                <Alert  variant="danger">
                {el.msg}
                </Alert>
        )
        }
        </div>
  )
}

export default Errors
