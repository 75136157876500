import React, { useState, useEffect,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getrapportwell } from "../Redux/Actions/RapportActions";
import { getrapporttwin } from "../Redux/Actions/RapportTwinAction";
import { getrapportspintoss } from "../Redux/Actions/RapportSpintossAction";
import axios from 'axios';

import { Table,Container } from "react-bootstrap";
import "./Home.css"; // Feuille CSS pour styles
import { getMyRequests, getRecivedRequests } from "../Redux/Actions/RequestActions";
import Login from "./Login";
import GestionPaie from "./GestionPaie";
import HomeGraph from './HomeGraph';

function Home() {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token')

  useEffect(() => {
    dispatch(getrapportwell());
    dispatch(getMyRequests());
    dispatch(getRecivedRequests());
    dispatch(getrapportspintoss());
  }, [dispatch]);

  const rapports = useSelector((state) => state.RapportReducer.rapports);

  // Filtrer les rapports pour le mois actuel
  const getCurrentMonthRange = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), 1); // 1er jour du mois
    const end = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Dernier jour du mois
    return { start, end };
  };

  const fetchMonthlyReports = () => {
    const { start, end } = getCurrentMonthRange();
    return rapports.filter((entry) => {
      const entryDate = new Date(entry.DateCollect);
      return entryDate >= start && entryDate <= end;
    });
  };

  const filteredData = fetchMonthlyReports();

  // Calcul des totaux
  const calculateTotals = (data) => {
    const totalAmount = parseFloat(
      data.reduce((acc, curr) => acc + curr.Amount, 0).toFixed(3)
    );
    const totalExpenses = parseFloat(
      data.reduce((acc, curr) => acc + curr.MonthExpenses, 0).toFixed(3)
    );
    const netResult = parseFloat((totalAmount - totalExpenses).toFixed(3));
  
    return { totalAmount, totalExpenses, netResult };
  };
  
  const { totalAmount, totalExpenses, netResult } = calculateTotals(filteredData);
  
  // Initialisation des états
  const [somme, setSomme] = useState({
    totalAmount: 0,
    totalDailyExpenses: 0,
    resultatnet: 0,
  });

  const RapportsTwin = useSelector((state) => state.RapportTwinReducer.RapportsTwin);

  // Vérification si 'rapports' est un tableau valide
  const validRapports = Array.isArray(RapportsTwin) ? RapportsTwin : [];

  // Dispatch action pour récupérer les rapports au montage du composant
  useEffect(() => {
    dispatch(getrapporttwin());
  }, [dispatch]);

  // Fonction pour calculer les totaux
  const calcul = (data) => {
    const totalAmount = data.reduce((acc, cur) => acc + (cur.TotalAmount || 0), 0);
    const totalDailyExpenses = data.reduce(
      (acc, cur) => acc + (cur.DailyExpenses || 0),
      0
    );
    const resultatnet = totalAmount - totalDailyExpenses;

    return {
      totalAmount,
      totalDailyExpenses,
      resultatnet,
    };
  };

  // Fonction pour exclure les données liées au partenariat
  const filterNonPartnershipData = (data) => {
    return data.filter((cur) => {
      return !(
        cur.MachineBoxe ||
        cur.MachineFoot ||
        cur.MachineBoxeEnfant ||
        cur.BillardAmericain ||
        cur.BallesGift
      );
    });
  };

  // Application du filtre pour exclure les données partenariat
  const validRapportsWithoutPartnership = filterNonPartnershipData(validRapports);

  // Regroupement des rapports par localisation
  const groupByLocalisation = (data) => {
    return data.reduce((acc, el) => {
      if (!acc[el.localisation]) {
        acc[el.localisation] = [];
      }
      acc[el.localisation].push(el);
      return acc;
    }, {});
  };

  const groupedReports = groupByLocalisation(validRapportsWithoutPartnership);

  // Calcul des sommes globales
  useEffect(() => {
    setSomme(calcul(validRapportsWithoutPartnership));
  }, [validRapportsWithoutPartnership]);

  // Filtrage des données de partenariat
  const filterPartnershipData = (data) => {
    return data.filter((cur) => {
      return (
        cur.MachineBoxe ||
        cur.MachineFoot ||
        cur.MachineBoxeEnfant ||
        cur.BillardAmericain ||
        cur.BallesGift
      );
    });
  };

  const validPartnershipReports = filterPartnershipData(validRapports);

  // Regroupement des rapports de partenariat par localisation
  const groupedPartnershipReports = groupByLocalisation(validPartnershipReports);

  // Calcul des totaux pour les données de partenariat
  const calculPartnership = (data) => {
    const totalAmountPartnership = data.reduce(
      (acc, cur) =>
        acc +
        (cur.MachineBoxe || 0) +
        (cur.MachineFoot || 0) +
        (cur.MachineBoxeEnfant || 0) +
        (cur.BillardAmericain || 0) +
        (cur.BallesGift || 0),
      0
    );

    const totalExpensesPartnership = data.reduce(
      (acc, cur) => acc + (cur.DailyExpenses || 0),
      0
    );

    const netResultPartnership = totalAmountPartnership - totalExpensesPartnership;

    return {
      totalAmountPartnership,
      totalExpensesPartnership,
      netResultPartnership,
    };
  };

  const [sommePartnership, setSommePartnership] = useState({});

  useEffect(() => {
    if (validPartnershipReports.length > 0) {
      setSommePartnership(calculPartnership(validPartnershipReports));
    }
  }, [validPartnershipReports]);

  const myRequests = useSelector((state) => state.ReducersRequest.myRequests);
  const myRecivedRequest = useSelector((state) => state.ReducersRequest.myRecivedRequest);

  // Fonction pour calculer le nombre de demandes non confirmées dans une catégorie donnée
  const categories = ["All", "For You Today", "Repetitive Tasks", "New Project"];

// Fonction pour compter les demandes non confirmées
const countUnconfirmedRequests = (requests, category) => {
    // Si la catégorie est "All", on additionne les demandes non confirmées des trois catégories spécifiques
    if (category === "All") {
        const allCategories = ["For You Today", "Repetitive Tasks", "New Project"];
        let count = 0;
        
        allCategories.forEach((cat) => {
            count += requests.filter(
                (request) =>
                    request.selectedCategory?.trim().toLowerCase() === cat.toLowerCase() &&
                    request.destinataire.some(destinataire => destinataire.status !== "Confirmed")
            ).length;
        });
        
        return count;
    }

    // Pour les autres catégories, on filtre normalement
    return requests.filter(
        (request) =>
            request.selectedCategory?.trim().toLowerCase() === category.toLowerCase() &&
            request.destinataire.some(destinataire => destinataire.status !== "Confirmed")
    ).length;
};



const rapportsMensuels = useSelector(
  (state) => state.RapportSpintossReducer.RapportsSpintoss || []
);

// Filtrer les rapports du mois courant et calculer les totaux
const currentMonthData = useMemo(() => {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();

  const filteredReports = rapportsMensuels.filter((rapport) => {
    const reportDate = new Date(rapport.DateCollect);
    return (
      reportDate.getMonth() === currentMonth &&
      reportDate.getFullYear() === currentYear
    );
  });

  const totalIncome = parseFloat(
    filteredReports.reduce((acc, cur) => acc + (cur.TotalAmount || 0), 0).toFixed(3)
  );
  
  const totalDailyExpenses = parseFloat(
    filteredReports.reduce((acc, cur) => acc + (cur.DailyExpenses || 0), 0).toFixed(3)
  );
  
  const resultatNet = parseFloat((totalIncome - totalDailyExpenses).toFixed(3));
  

  return { filteredReports, totalIncome, totalDailyExpenses, resultatNet };
}, [rapportsMensuels]);

const { totalIncome, totalDailyExpenses, resultatNet } = currentMonthData;

const tableData = [
  {
    libelle: "Spintoss",
    totalIncome: totalIncome,
    totalDailyExpenses: totalDailyExpenses,
    resultatNet: resultatNet,
  },
];


const calculateGlobalTotals = () => {
  const globalAmount = (totalAmount + totalIncome + somme.totalAmount + (sommePartnership.totalAmountPartnership || 0)).toFixed(3);
  const globalExpenses = (totalExpenses + totalDailyExpenses + somme.totalDailyExpenses + (sommePartnership.totalExpensesPartnership || 0)).toFixed(3);
  const globalNetResult = globalAmount - globalExpenses;

  return { globalAmount, globalExpenses, globalNetResult };
};

const { globalAmount, globalExpenses, globalNetResult } = calculateGlobalTotals();


const [todoItems, setTodoItems] = useState([]);
const [newTask, setNewTask] = useState("");

// Fonction pour ajouter une tâche
const addTodoItem = () => {
  if (newTask.trim() === "") return; // Empêche l'ajout d'une tâche vide

  const newItem = {
    id: Date.now(),
    text: newTask,
    completed: false,
  };
  setTodoItems([...todoItems, newItem]);
  setNewTask(""); // Réinitialise le champ de saisie
};

// Fonction pour marquer une tâche comme complétée
const toggleCompletion = (id) => {
  setTodoItems(
    todoItems.map((item) =>
      item.id === id ? { ...item, completed: !item.completed } : item
    )
  );
};

// Fonction pour supprimer une tâche
const removeTodoItem = (id) => {
  setTodoItems(todoItems.filter((item) => item.id !== id));
};

  return (
   <div>
    
    {
      token ? 

      <div className="home-container">
        
      <div>
      {/* ... autres tableaux */}
      
      {/* Tableau récapitulatif */}
      <h3>Résumé Global</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Libellé</th>
            <th>Recettes Globales</th>
            <th>Dépenses Globales</th>
            <th>Résultat Net Globales</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Global</td>
            <td>{globalAmount}</td>
            <td>{globalExpenses}</td>
            <td>{globalNetResult}</td>
          </tr>
        </tbody>
      </Table>
      </div>


      
      <div>
      <h3>Rapport Spintoss</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Libellé</th>
            <th>Recettes Totales</th>
            <th>Dépenses Totales</th>
            <th>Résultat Net</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => (
            <tr key={index}>
              <td>{data.libelle}</td>
              <td>{data.totalIncome}</td>
              <td>{data.totalDailyExpenses}</td>
              <td>{data.resultatNet}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
      
      <h3>Rapports Wellness du mois</h3>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Libéllé</th>
            <th>Recettes Totales</th>
            <th>Dépenses Totales</th>
            <th>Résultat Net</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Wellness</td>
            <td>{totalAmount}</td>
            <td>{totalExpenses}</td>
            <td>{netResult}</td>
          </tr>
        </tbody>
      </Table>

      {/* Tableau des rapports regroupés par localisation avec les totaux globaux */}
      <div className="filtered-reports-section">
        <h3>Rapports Twin Park du mois</h3>
        {Object.keys(groupedReports).length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Localisation</th>
                <th>Recettes Totales</th>
                <th>Dépenses Totales</th>
                <th>Résultat Net</th>
              </tr>
            </thead>
            <tbody>
              {/* Lignes pour les localisations */}
              {Object.keys(groupedReports).map((localisation, idx) => {
                const reportsForLocalisation = groupedReports[localisation];
                const totalsForLocalisation = calcul(reportsForLocalisation);
                return (
                  <tr key={idx}>
                    <td>{localisation}</td>
                    <td>{totalsForLocalisation.totalAmount}</td>
                    <td>{totalsForLocalisation.totalDailyExpenses}</td>
                    <td>{totalsForLocalisation.resultatnet}</td>
                  </tr>
                );
              })}


              {/* Ligne pour les totaux globaux */}

              <tr style={{ fontWeight: "bold" }}>
                <td>Global</td>
                <td>{somme.totalAmount}</td>
                <td>{somme.totalDailyExpenses}</td>
                <td>{somme.resultatnet}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <p>Aucun rapport disponible pour ce mois.</p>
        )}
      </div>

      {/* Nouveau tableau uniquement pour le Partenariat avec localisation */}
      <h3>Rapport Partenariat</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Localisation</th>
            <th>Recettes Totales</th>
            <th>Dépenses Totales</th>
            <th>Résultat Net</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedPartnershipReports).length > 0 ? (
            Object.keys(groupedPartnershipReports).map((localisation, idx) => {
              const reportsForLocalisation = groupedPartnershipReports[localisation];
              const totalsForLocalisation = calculPartnership(reportsForLocalisation);
              return (
                <tr key={idx}>
                  <td>{localisation}</td>
                  <td>{totalsForLocalisation.totalAmountPartnership}</td>
                  <td>{totalsForLocalisation.totalExpensesPartnership}</td>
                  <td>{totalsForLocalisation.netResultPartnership}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>Aucun rapport de partenariat trouvé.</td>
            </tr>
          )}
          {/* Ligne des totaux globaux de partenariat */}
          <tr style={{ fontWeight: "bold" }}>
            <td>Total Partenariat</td>
            <td>{sommePartnership.totalAmountPartnership}</td>
            <td>{sommePartnership.totalExpensesPartnership}</td>
            <td>{sommePartnership.netResultPartnership}</td>
          </tr>
        </tbody>
      </Table>
      <GestionPaie/>
   <div className="details-todo-wrapper">
  {/* Détails des demandes */}
  <div className="details-section">
    <h3>Détails des demandes</h3>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Demandes Non Confirmées</th>
          <th>Demandes envoyées</th>
          <th>Demandes Reçues</th>
        </tr>
      </thead>
      <tbody>
        {categories.map((category) => (
          <tr key={category}>
            <td>{category}</td>
            <td>{countUnconfirmedRequests(myRequests, category)}</td>
            <td>{countUnconfirmedRequests(myRecivedRequest, category)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>

  {/* Section Todo-List */}
  <div style={{ textAlign: "center", fontFamily: "Arial, sans-serif" }}>
      {/* Titre */}
      <h3 style={{ marginBottom: "20px", fontSize: "24px", color: "#5A2493" }}>
        To Do List
      </h3>

      {/* Champ de saisie et bouton Ajouter */}
      <div
        style={{
          border: "2px solid #5A2493",
          borderRadius: "10px",
          padding: "20px",
          maxWidth: "400px",
          margin: "0 auto 20px",
        }}
      >
        <input
          type="text"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          placeholder="Ajouter une tâche"
          style={{
            padding: "10px",
            width: "70%",
            borderRadius: "5px",
            border: "1px solid #ccc",
            marginRight: "10px",
          }}
        />
        <button
          onClick={addTodoItem}
          style={{
            backgroundColor: "#AF0602",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            padding: "10px 15px",
            cursor: "pointer",
          }}
        >
          Ajouter
        </button>
      </div>

      {/* Liste des tâches */}
      <div
        style={{
          border: "2px solid #5A2493",
          borderRadius: "10px",
          padding: "20px",
          maxWidth: "400px",
          margin: "0 auto",
        }}
      >
        <ul className="todo-list" style={{ listStyleType: "none", padding: "0" }}>
          {todoItems.map((item) => (
            <li
              key={item.id}
              style={{
                margin: "10px 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textDecoration: item.completed ? "line-through" : "none",
                fontSize: "18px",
              }}
            >
              <div
                className="form-check"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={item.completed}
                  onChange={() => toggleCompletion(item.id)}
                  style={{ marginRight: "10px" }}
                />
                <label className="form-check-label">{item.text}</label>
              </div>
              <button
                className="remove"
                onClick={() => removeTodoItem(item.id)}
                style={{
                  backgroundColor: "#AF0602",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  padding: "5px 10px",
                }}
              >
                ✖
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>


  </div>
</div>



    :

    <Login/>
    }
   </div>
  );
}

export default Home;



// <table>
        //         <thead>
        //             <tr>
        //                 <th>Zone</th>
        //                 <th>Pourcentage Conforme</th>
        //                 <th>Pourcentage Non Conforme</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {Object.keys(zonePercentages).map((zone) => (
        //                 <tr key={zone}>
        //                     <td>{zone}</td>
        //                     <td>{zonePercentages[zone].pourcentageConforme}%</td>
        //                     <td>{zonePercentages[zone].pourcentageNonConforme}%</td>
        //                 </tr>
        //             ))}
        //         </tbody>
        //     </table> *